import React, {Component} from "react";
import {
    Button,
    Col, DatePicker,
    Form,
    Input,
    Modal,
    notification,
    Row,
    Select, Space, Switch,
    Table, Tabs, Tooltip,
    Typography
} from "antd";
import {
    PlayCircleOutlined,
    EditOutlined,
    KeyOutlined,
    DownloadOutlined,
    SearchOutlined,
    DeleteOutlined,
    CloseCircleOutlined,
    PlusCircleOutlined,
    CloseSquareOutlined,
    MinusCircleOutlined,
    PlusOutlined,
    FormOutlined,
    SoundOutlined
} from '@ant-design/icons';
import api from "../../api";
import {connect} from "react-redux";
import TextArea from "antd/es/input/TextArea";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/src/styles.scss'
import {Editor} from "@tinymce/tinymce-react";
import moment from "moment";

const {TabPane} = Tabs;
const {RangePicker} = DatePicker;

class SongsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            noteModal: false,
            editsongVis: false,
            note: null,
            song: {},
            filters: {},
            playMusic: false,
            categories: {},
            songWritersCount: 0,
        }
        this.form = React.createRef();
        this.songForm = React.createRef();
        this.player = React.createRef();
    }
    handleGetSongUri = (uri,songId)=> fetch(`${api.Song.GetDownload.url}downloadlink?path=${uri}&productId=${songId}`, {
        headers: {
            'Accept': 'application/json',
            'cache-control': 'no-cache',
            'Content-Type': 'application/json',
            'Authorization': this.props.state.auth.accessToken
        },
        method: api.Song.GetDownload.method,
    }).then(resp=>resp.json())
    copyFunction = (uri,song) => {
       this.handleGetSongUri(uri,song.songId).then(resp=> {
                if(resp){
                    const el = document.createElement('a');
                    el.href = resp;
                    el.setAttribute('download', true);
                    el.style = {position: 'absolute', left: '-9999px'};
                    document.body.appendChild(el);
                    el.click();
                    notification.info({
                        message: 'Download',
                        description: 'Download Started'
                    })
                }
        })
    }
    makeSongFeatured = (song, bool) => {
        this.setState({
            loading: true
        })
        fetch(api.Song.MakeFeatured.url + song.songId + '?up=' + (bool ? 'false' : 'true'), {
            headers: {
                'Accept': 'application/json',
                'cache-control': 'no-cache',
                'Content-Type': 'application/json',
                'Authorization': this.props.state.auth.accessToken
            },
            method: api.Song.MakeFeatured.method,
        }).then(response => {
            if (response.ok) {
                notification.info({
                    message: 'Done',
                    description: bool ? 'Song is removed from featured list' : 'Song is featured'
                })
            } else {
                notification.error({
                    message: 'Error',
                    description: 'Somethings went wrong.'
                })
            }
            this.setState({
                loading: false
            })
        })
    }
    getColumns = () => [
        {
            title: 'Date Added',
            dataIndex: 'dateAdded',
            sorter: (a, b) => moment(a.dateAdded).unix() - moment(b.dateAdded).unix(),
            key: 'dateAdded',
            fixed: 'left',
            render: item => moment(item).format('YYYY-MM-DD')
        },
        {
            title: 'Title',
            dataIndex: 'title',
            fixed: 'left',
            sorter: (a, b) => a.title.localeCompare(b.title),
            key: 'title',
        },
        {
            title: 'Provider',
            dataIndex: ['owner', 'name'],
            sorter: (a, b) => a.owner.name.localeCompare(b.owner.name),
            key: 'provider',
        },
        {
            title: 'Genres',
            dataIndex: 'genres',
            key: 'genres',
        },
        {
            title: 'Tempo',
            dataIndex: 'tempo',
            sorter: (a, b) => {
                if (a.tempo && b.tempo) {
                    return a.tempo.localeCompare(b.tempo)
                }
                return false
            },
            key: 'tempo',
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            sorter: (a, b) => {
                if (a.gender && b.gender) {
                    return a.gender.localeCompare(b.gender)
                }
                return false
            },
            key: 'gender',
        },
        {
            title: 'Rank',
            dataIndex: 'rating',
            sorter: (a, b) => b.rating - a.rating,
            key: 'rating',
            className: 'centered-content'
        },
        {
            title: 'Admin Disabled',
            dataIndex: 'adminDisabled',
            sorter: (a, b) => (a.adminDisabled ? 'y' : 'n').localeCompare(b.adminDisabled ? 'y' : 'n'),
            className: 'centered-content',
            key: 'adminDisabled',
            render: (item, record) => {
                return <Switch defaultChecked={item} onChange={(value) => {
                    this.setStatus(record, value)
                }}/>
            }
        },
        {
            title: 'Note',
            dataIndex: 'note',
            className: 'centered-content',
            key: 'note',
            render: (item, record) => item ?
                <Button size={'small'} onClick={() => this.openNoteModal(record, item)} type={'link'}>{item}</Button> :
                <Button size={'small'} onClick={() => this.openNoteModal(record, null)}><PlusCircleOutlined/></Button>
        },
        {
            title: 'Files',
            dataIndex: 'licenseTypes',
            key: 'licenseTypes',
            children: [
                {
                    title: 'Song',
                    dataIndex: 'vocalsFull',
                    className: 'centered-content',
                    key: 'vocalsFull',
                    render: (item, record) => item ?
                        <div style={{display: 'inline-flex'}}><Button style={{padding: '4px'}}


                                                                      id={record.songId + 'vocals'} onClick={async (e) => {
                            if (this.state.playMusic && document.getElementsByClassName('current-playing')[0]) {
                                document.getElementsByClassName('current-playing')[0].classList.remove('current-playing')
                            }
                            e.currentTarget.classList.add('current-playing')
                            this.setState({
                                playMusic:  await this.handleGetSongUri(item,record.songId)
                            })
                        }}><PlayCircleOutlined style={record.isDeleted?{color:'red'}:null}/></Button><Button style={{padding: '4px'}}
                                                                 onClick={() => this.copyFunction(item,record)}><DownloadOutlined style={record.isDeleted?{color:'red'}:null}/></Button>
                        </div> : record.isAllInstrumentalsDeleted ?
                            <CloseCircleOutlined style={{fontSize: '16px', color: 'red'}}/> : null
                },
                {
                    title: 'MP3',
                    dataIndex: 'mp3Instrumental',
                    className: 'centered-content',
                    key: 'mp3Instrumental',
                    render: (item, record) => {
                        if (record.isAllInstrumentalsDeleted) {
                            return item? <Tooltip title={'Track deleted'}><div style={{display: 'inline-flex'}}><Button style={{padding: '4px'}}

                                                                                      onClick={async (e) => {
                                                                                          if (this.state.playMusic && document.getElementsByClassName('current-playing')[0]) {
                                                                                              document.getElementsByClassName('current-playing')[0].classList.remove('current-playing')
                                                                                          }
                                                                                          e.currentTarget.classList.add('current-playing')
                                                                                          this.setState({
                                                                                              playMusic:  await this.handleGetSongUri(item,record.songId)
                                                                                          })
                                                                                      }}><PlayCircleOutlined style={{color: 'red'}}/></Button><Button
                                style={{padding: '4px'}}
                                onClick={() => this.copyFunction(item,record)}><DownloadOutlined style={{color: 'red'}}/></Button></div></Tooltip>:<CloseCircleOutlined style={{fontSize: '16px', color: 'red'}}/>
                        }
                        if (item && record.priceOfExpandedWav) {
                            return <div>
                                <div style={{display: 'inline-flex'}}><Button style={{padding: '4px'}} onClick={async (e) => {
                                    if (this.state.playMusic && document.getElementsByClassName('current-playing')[0]) {
                                        document.getElementsByClassName('current-playing')[0].classList.remove('current-playing')
                                    }
                                    e.currentTarget.classList.add('current-playing')
                                    this.setState({
                                        playMusic: await this.handleGetSongUri(item,record.songId)
                                    })
                                }}><PlayCircleOutlined/></Button><Button style={{padding: '4px'}}
                                                                         onClick={() => this.copyFunction(item,record)}><DownloadOutlined/></Button>
                                </div>
                                <br/>
                                <div style={{display: 'inline-flex'}}><Button style={{padding: '4px'}}
                                                                              onClick={() => this.copyFunction(record.wavInstrumental,record)}><DownloadOutlined/></Button>
                                </div>
                            </div>
                        }
                        if (item) {
                            return <div style={{display: 'inline-flex'}}><Button style={{padding: '4px'}}
                                                                                 onClick={async (e) => {
                                                                                     if (this.state.playMusic && document.getElementsByClassName('current-playing')[0]) {
                                                                                         document.getElementsByClassName('current-playing')[0].classList.remove('current-playing')
                                                                                     }
                                                                                     e.currentTarget.classList.add('current-playing')
                                                                                     this.setState({
                                                                                         playMusic:  await this.handleGetSongUri(item,record.songId)
                                                                                     })
                                                                                 }}><PlayCircleOutlined/></Button><Button
                                style={{padding: '4px'}}
                                onClick={() => this.copyFunction(item,record)}><DownloadOutlined/></Button></div>
                        }
                        if (record.priceOfExpandedWav) {
                            return <div style={{display: 'inline-flex'}}><Button style={{padding: '4px'}}
                                                                                 onClick={() => this.copyFunction(record.wavInstrumental,record)}><DownloadOutlined/></Button>
                            </div>
                        }

                    }
                },
                {
                    title: 'WAV',
                    dataIndex: 'wavInstrumental',
                    className: 'centered-content',
                    key: 'wavInstrumental',
                    render: (item, record) => record.isWAVDeleted || record.isAllInstrumentalsDeleted ?
                        item?  <Tooltip title={'Track deleted'}><div style={{display: 'inline-flex'}}><Button style={{padding: '4px'}}  onClick={async (e) => {
                                if (this.state.playMusic && document.getElementsByClassName('current-playing')[0]) {
                                    document.getElementsByClassName('current-playing')[0].classList.remove('current-playing')
                                }
                                e.currentTarget.classList.add('current-playing')
                                this.setState({
                                    playMusic: await this.handleGetSongUri(item,record.songId)
                                })
                            }}><PlayCircleOutlined style={{color: 'red'}}/></Button><Button   style={{padding: '4px'}}
                                                                     onClick={() => this.copyFunction(item,record)}><DownloadOutlined style={{color: 'red'}}/></Button>
                            </div></Tooltip>:
                            <CloseCircleOutlined style={{fontSize: '16px', color: 'red'}}/> : (item ?
                            <div style={{display: 'inline-flex'}}><Button style={{padding: '4px'}} onClick={async (e) => {
                                if (this.state.playMusic && document.getElementsByClassName('current-playing')[0]) {
                                    document.getElementsByClassName('current-playing')[0].classList.remove('current-playing')
                                }
                                e.currentTarget.classList.add('current-playing')
                                this.setState({
                                    playMusic: await this.handleGetSongUri(item,record.songId)
                                })
                            }}><PlayCircleOutlined/></Button><Button style={{padding: '4px'}}
                                                                     onClick={() => this.copyFunction(item,record)}><DownloadOutlined/></Button>
                            </div> : null)
                },
                {
                    title: 'Stem',
                    dataIndex: 'masterTrack',
                    className: 'centered-content',
                    key: 'masterTrack',
                    render: (item, record) => record.isSTEMSDeleted  && !item?
                        <CloseCircleOutlined style={{fontSize: '16px', color: 'red'}}/> : (item ?
                            <Tooltip placement="top" title={`${record.isSTEMSDeleted}Deleted Stems - $${record.master ? record.master : 0}`}>
                                <div style={{display: 'inline-flex'}}><Button type={record.isSTEMSDeleted?'danger':'secondary'} style={{padding: '4px'}}
                                                                              onClick={() => this.copyFunction(item,record)}><DownloadOutlined/></Button>
                                </div>
                            </Tooltip> : null)
                },
            ],
        },
        {
            title: 'Make song featured',
            key: 'isRecent',
            className: 'isRecent',
            width: 100,
            render: (item, record) => {
                return <>
                    <Button icon={<MinusCircleOutlined/>} onClick={() => this.makeSongFeatured(record, true)}/>
                    <Button icon={<PlusCircleOutlined/>} onClick={() => this.makeSongFeatured(record, false)}/>
                </>
            }
        },
        {
            title: 'Action',
            key: 'operation',
            className: 'centered-content',
            width: 100,
            fixed: 'right',
            render: (item, record) => {
                const data = {...record};
                return <> <Button size={'small'} onClick={() => this.openEditModal(data)}><EditOutlined size={'small'}/></Button>
                    {!data?.isDeleted &&
                        <Button size={'small'} onClick={() => this.handleShowRemoveModal(data)}><DeleteOutlined style={{color: 'red'}}
                            size={'small'}/></Button>}
                </>
            }
        },

    ];
    openNoteModal = (record, note) => {
        this.setState({
            noteModal: true,
            song: record,
            note: note,
        })
    }
    handleCloseNote = () => {
        this.setState({
            noteModal: false,
            song: {},
            note: null,
        })
    }
    handleRemove = (song, type,modal) => {
        modal.destroy();
        fetch(`${api.Song.Remove.url}${song.songId}?type=${type}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'cache-control': 'no-cache',
                'Authorization': this.props.state.auth.accessToken
            },
            method: api.Song.Remove.method,
        }).then(response => {

            if (response.ok) {
                notification.info({
                    message: 'Done',
                    description: 'Song successfully removed!'
                })
                return response.json()
            } else {
                notification.error({
                    message: 'Error',
                    description: 'Somethings went wrong.'
                })
            }
            return false;
        }).then(response => {
            if (response) {
                this.props.onRemoveSong(response)
            }
        }).finally(() => {
            this.setState({
                loading: false
            })
        })
    }
    handleShowRemoveModal = (data) => {
       let modal = Modal.error({
            title: `Remove Song (${data.title})?`,
           width:300,
           className:'remove-modal',
            content: (
                <div style={{textAlign: 'center'}}>
                    <div>
                        <Space direction={'vertical'} className={'remove-buttons'}>
                            <Button type={'danger'} onClick={() => this.handleRemove(data, 'all',modal)}>All</Button>
                            {data?.mp3Instrumental && !data?.isAllInstrumentalsDeleted &&
                                <Button type={'danger'}
                                    onClick={() => this.handleRemove(data, 'allinstrumentals',modal)}>All
                                    Instrumentals</Button>}
                            {data?.wavInstrumental && !data?.isWAVDeleted && <Button type={'danger'}
                                onClick={() => this.handleRemove(data, 'wavinstrumentals',modal)}>WAV
                                Instrumentals</Button>}
                            {data?.masterTrack && !data?.isSTEMSDeleted &&
                                <Button type={'danger'} onClick={() => this.handleRemove(data, 'stems',modal)}>STEMS</Button>}
                        </Space>
                    </div>
                </div>
            ),
           okText:'Cancel',
           okType:'default'
        })

    }
    openEditModal = (record) => {
        let data = [];
        record.genres = record.genres.split(', ').filter(function (elem, index, self) {
            return index === self.indexOf(elem);
        })
        record.genres.filter(genre => {
            return this.state.categories.genres.map(catGenre => catGenre.name === genre ? data.push(catGenre.id) : null)
        })
        record.genres = data;
        data = [];
        record.mood = record.mood.split(', ').filter(function (elem, index, self) {
            return index === self.indexOf(elem);
        })
        record.mood.filter(genre => {
            return this.state.categories.moods.map(catGenre => catGenre.name === genre ? data.push(catGenre.id) : null)
        })
        record.mood = data;
        data = [];
        record.soundsLike.split(', ').filter(genre => {
            return this.state.categories.soundsLikes.map(catGenre => catGenre.name === genre ? data.push(catGenre.id) : null)
        })
        record.soundsLike = data;

        data = [];
        this.state.categories.tempos.map(catGenre => catGenre.name === record.tempo ? data = catGenre.id : null)
        record.tempo = data;
        data = [];
        this.state.categories.genders.map(catGenre => catGenre.name === record.gender ? data = catGenre.id : null)
        record.gender = data;
        this.setState({
            editsongVis: true,
            song: record,
        })
    }
    handleCancelEdit = () => {
        this.setState({
            editsongVis: false,
            song: null,
        })
    }

    componentDidMount() {
        this.setState({
            loading: true,
        });
        Promise.all([
            fetch(`${api.Song.Search.url}${this.state.searchString ?? ''}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'cache-control': 'no-cache',
                    'Authorization': this.props.state.auth.accessToken
                },
                method: api.Song.Search.method,
            }).then(response => {
                if (response.ok) {
                    return response.json()
                } else if (response.status === 401) {
                    this.props.logOut()
                    this.props.history.push('/')
                } else {
                    notification.error({
                        message: 'Error',
                        description: 'Please contact administrator.'
                    })
                }
            }),
            fetch(api.Song.Categories.url, {
                headers: {
                    'Accept': 'application/json',
                    'cache-control': 'no-cache',
                    'Content-Type': 'application/json',
                    'Authorization': this.props.state.auth.accessToken
                },
                method: api.Song.Categories.method,
            }).then(response => response.json()),
        ]).then((responses) => {
            if (responses[0] && responses[1]) {
                this.props.onFetchSongs(responses[0])
                this.setState({
                    loading: false,
                    categories: responses[1]
                });
            } else {
                notification.error({
                    message: 'Error',
                    description: 'Please contact administrator.'
                })
            }
        })

    }

    handleCreateNote = formData => {
        const data = JSON.stringify(formData);
        this.setState({
            loading: true,
        })
        fetch(api.Song.Product.url + 'addnote/' + (this.state.song.songId), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.state.auth.accessToken
            },
            method: api.Song.Product.method,
            body: data,
        })
            .then(response => {
                if (response.ok) {
                    this.props.onAddNote({...this.state.song, note: formData['note']})
                    this.setState({
                        loading: false,
                        noteModal: false,
                        note: null,
                        song: {}
                    })
                } else {
                    notification.error({
                        message: 'Error',
                        description: 'Please contact administrator.'
                    })
                    this.setState({
                        loading: false,
                        noteModal: false,
                        note: null,
                        song: {}
                    })
                }

            })
    }
    setStatus = (record, value) => {
        this.setState({
            loading: true,
        });
        const status = value === false ? 'approve' : 'reject';
        fetch(api.Song.Product.url + status + `/${record.songId}`, {
            headers: {
                'Accept': 'application/json',
                'cache-control': 'no-cache',
                'Content-Type': 'application/json',
                'Authorization': this.props.state.auth.accessToken
            },
            method: api.Song.Product.method,
        })
            .then(response => {
                if (response.ok) {
                    this.setState({
                        loading: false,
                    });
                } else {
                    notification.error({
                        message: 'Error',
                        description: 'Please contact administrator.'
                    })
                }


            })
    }
    filterHandler = formData => {
        if (formData['date']) {
            formData['createdTo'] = moment.utc(formData.date[1]).format()
            formData['createdFrom'] = moment.utc(formData.date[0]).format()
            delete formData['date'];
        }
        this.setState({loading: true})
        Object.keys(formData).forEach(key => (formData[key] === undefined || formData[key] === '' || (!Number.isInteger(formData[key]) && !formData[key].length && typeof formData[key] !== 'boolean')) && delete formData[key])
        const search = Object.keys(formData).map(element => {
            return Array.isArray(formData[element]) && formData[element].length ? formData[element].map(singleelement => element + '=' + singleelement).toString() : element + '=' + formData[element]
        });

        const searchString = search.toString().replace(/,/g, '&');
        this.setState({
            searchString: '?' + searchString
        })
        fetch(api.Song.Search.url + `?${searchString}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'cache-control': 'no-cache',
                'Authorization': this.props.state.auth.accessToken
            },
            method: api.Song.Search.method,
        }).then(response => response.json()).then(response => {
            this.props.onFetchSongs(response)
            this.setState({loading: false, editsongVis: false, filters: formData})
        });
    }
    handeEditSong = values => {
        let writerShare = 0
        if (values.songwriters && values.songwriters.length) {
            values.songwriters.map(sogwriter => {
                if (!isNaN(parseInt(sogwriter.share))) {
                    writerShare = writerShare + parseInt(sogwriter.share);
                }
                return sogwriter
            })

        } else if (this.state.song.songwriters) {
            this.state.song.songwriters.map(sogwriter => {
                if (!isNaN(parseInt(sogwriter.share))) {
                    writerShare = writerShare + parseInt(sogwriter.share);
                }
                return sogwriter
            })
        }
        let publShare = 0;
        if (values.publishers && values.publishers.length) {
            values.publishers.map(publisher => {
                if (!isNaN(parseInt(publisher.share))) {
                    publShare = publShare + parseInt(publisher.share);
                }
                return publisher
            })
        } else if (this.state.song.publishers && this.state.song.publishers.length) {
            this.state.song.publishers.map(publisher => {
                if (!isNaN(parseInt(publisher.share))) {
                    publShare = publShare + parseInt(publisher.share);
                }
                return publisher
            })
        }

        if (publShare <= 100 && writerShare <= 100) {
            this.setState({
                loading: true,
            })
            const formData = new FormData();

            formData.append('ProductId', this.state.song.songId ? this.state.song.songId : values.songId)

            switch (this.state.song.priceOfExpandedWav) {
                case 24.95:
                    formData.append('expandedWavLicense', '1c4dd9d2-c901-46dd-93c7-7465ce6b8248')
                    break;
                case 29.95:
                    formData.append('expandedWavLicense', '2713262c-35fc-482f-95aa-e516f558213c')
                    break;
                case 34.95:
                    formData.append('expandedWavLicense', '47af131b-70e1-45dd-b2cf-e4cf4407f8b9')
                    break;
                case 39.95:
                    formData.append('expandedWavLicense', '578da908-fa29-45a9-8772-8c2436978f60')
                    break;
                default:
                    break;

            }
            switch (this.state.song.master) {
                case 295:
                    formData.append('masterLicense', 'baf94f4b-5baa-4ff8-8e51-dd07f26056ba')
                    break;
                case 495:
                    formData.append('masterLicense', '5e868377-865f-4f70-ae31-f6d51fa79d01')
                    break;
                case 695:
                    formData.append('masterLicense', '82d084b5-acb5-4ec9-a77b-318723350c1b')
                    break;
                case 895:
                    formData.append('masterLicense', '6cec9e1d-b740-441c-95e0-9906931dfd9e')
                    break;
                default:
                    break;

            }

            if (values.songwriters && values.songwriters.length) {
                values.songwriters.map((songwriter, key) => {
                    formData.append(`songwriters[${key}][Name]`, songwriter.name)
                    formData.append(`songwriters[${key}][Proid]`, songwriter.proid)
                    formData.append(`songwriters[${key}][Ipiid]`, songwriter.ipiid)
                    if (songwriter.share) {
                        formData.append(`songwriters[${key}][Share]`, songwriter.share)
                    }
                    return null;
                })

            } else if (this.state.song.songwriters) {
                this.state.song.songwriters.map((songwriter, key) => {
                    formData.append(`songwriters[${key}][Name]`, songwriter.name)
                    formData.append(`songwriters[${key}][Proid]`, songwriter.proid)
                    formData.append(`songwriters[${key}][Ipiid]`, songwriter.ipiid)
                    if (songwriter.share) {
                        formData.append(`songwriters[${key}][Share]`, songwriter.share)
                    }
                    return null;
                })
            }
            if (values.publishers && values.publishers.length) {
                values.publishers.map((songwriter, key) => {
                    formData.append(`publishers[${key}][Name]`, songwriter.name)
                    formData.append(`publishers[${key}][Proid]`, songwriter.proid)
                    formData.append(`publishers[${key}][Ipiid]`, songwriter.ipiid)
                    if (songwriter.share) {
                        formData.append(`publishers[${key}][Share]`, songwriter.share)
                    }
                    return null;
                })

            } else if (this.state.song.publishers) {
                this.state.song.publishers.map((songwriter, key) => {
                    formData.append(`publishers[${key}][Name]`, songwriter.name)
                    formData.append(`publishers[${key}][Proid]`, songwriter.proid)
                    formData.append(`publishers[${key}][Ipiid]`, songwriter.ipiid)
                    if (songwriter.share) {
                        formData.append(`publishers[${key}][Share]`, songwriter.share)
                    }
                    return null;
                })
            }
            formData.append('VendorId', this.state.song.owner ? this.state.song.owner.vendorId : values.owner.vendorId)
            delete values['songwriters']
            delete values['publishers']
            Object.keys(values).map((key) => {
                if (values[key] !== "" && values[key] !== undefined && values[key].length) {
                    if (Array.isArray(values[key])) {
                        values[key].map(singlevalue => {
                            formData.append(key + '[]', singlevalue)
                            return singlevalue;
                        })
                    } else {
                        formData.append(key, values[key]);
                    }
                }
                return null;
            })

            const p1 = fetch(`${api.Song.Update.url}`, {
                headers: {
                    'Authorization': this.props.state.auth.accessToken
                },
                method: api.Song.Update.method,
                body: formData
            })
            const p2 = fetch(`${api.Song.Update.urlRating}/${this.state.song.songId}?rating=${values.Rating}`, {
                headers: {
                    'Authorization': this.props.state.auth.accessToken
                },
                method: api.Song.Update.method,
            })
            Promise.all([p1, p2])
                .then(([res1, res2]) => {
                    if (res1.ok && res2.ok) {
                        notification.info({
                            message: 'Done',
                            description: 'Song is updated'
                        })
                    } else {
                        notification.error({
                            message: 'Error',
                            description: 'Somethings wrong'
                        })
                    }
                    this.filterHandler(this.state.filters)
                    this.setState({
                        loading: false,
                    })
                })
        } else {
            notification.warning({
                message: 'Warning',
                description: 'Songwriters and Publishers shares amount must be 100'
            })
        }
    }
    expandableContent = (content) => {
        const cols = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'PRO Name',
                dataIndex: 'proid',
                key: 'proidik',
            },
            {
                title: 'IPI Number',
                dataIndex: 'ipiid',
                key: 'ipiid',
            },
            {
                title: 'Share',
                dataIndex: 'share',
                key: 'share',
                render: item => item ? item + '%' : null
            },
        ]
        return <div style={{display: 'flex'}}>
            <Table pagination={false} title={() => <h4>Songwriters</h4>}
                   style={{width: '50%'}} dataSource={content.songwriters}
                   loading={this.state.loading} rowKey={record => record.title} columns={cols}
                   bordered/>
            <Table style={{width: '50%'}} title={() => <h4>Publishers</h4>}
                   pagination={false} dataSource={content.publishers} loading={this.state.loading}
                   rowKey={record => record.title} columns={cols} bordered/></div>
    }

    render() {
        const {Option} = Select;
        const {Title} = Typography;
        return (
            <Row className={'tab-content'}>
                <div className={'PlayerContainer ' + (this.state.playMusic ? 'showplayer' : '')}>
                    <span className={'close-btn'} onClick={() => {
                        this.player.current.audio.current.pause();
                        if (this.state.playMusic && document.getElementsByClassName('current-playing')[0]) {
                            document.getElementsByClassName('current-playing')[0].classList.remove('current-playing')
                        }
                        this.setState({
                            playMusic: false,
                        })
                    }}><CloseSquareOutlined/></span>

                    <AudioPlayer ref={this.player} autoPlay={true}
                                 src={this.state.playMusic ? this.state.playMusic : undefined}/>
                </div>
                <Col lg={24}>

                    <Form layout={'horizontal'} onFinish={this.filterHandler}>
                        <Row className={'filters-row'}>
                            <Col lg={24} md={24} sm={24} xs={24}><Title level={4}>Basic filters</Title></Col>
                            <Col xl={4} lg={6} md={24} sm={24} xs={24}>
                                <Form.Item name={'keyword'}>
                                    <Input placeholder="Keyword"/>
                                </Form.Item>
                                <Form.Item name="date" rules={[{
                                    type: 'array',
                                    message: 'Please select time!',
                                },]}>
                                    <RangePicker placeholder={['Uploaded From', 'Uploaded To']} style={{width: '100%'}}
                                                 format="YYYY-MM-DD"/>
                                </Form.Item>

                            </Col>
                            <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                                <Form.Item name={'genres'}>
                                    <Select mode="multiple" placeholder="genres">
                                        {this.state.categories.genres ? this.state.categories.genres.map((genr, index) =>
                                            <Option value={genr.name} key={index}>{genr.name}</Option>) : null}
                                    </Select>
                                </Form.Item>
                                <Form.Item name={'soundslikes'}>
                                    <Select mode="multiple" placeholder="Sounds Like">
                                        {this.state.categories.soundsLikes ? this.state.categories.soundsLikes.map((soundslike, index) =>
                                            <Option value={soundslike.name}
                                                    key={index}>{soundslike.name}</Option>) : null}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xl={3} lg={3} md={6} sm={24} xs={24}>
                                <Form.Item name={'moods'}>
                                    <Select mode="multiple" placeholder="Mood">
                                        {this.state.categories.moods ? this.state.categories.moods.map((mood, index) =>
                                            <Option value={mood.name} key={index}>{mood.name}</Option>) : null}
                                    </Select>
                                </Form.Item>
                                <Form.Item name={'gender'}>
                                    <Select placeholder="Gender">
                                        <Option value={''} key={'All'}>All</Option>
                                        {this.state.categories.genders ? this.state.categories.genders.map((gender, index) =>
                                            <Option value={gender.name} key={index}>{gender.name}</Option>) : null}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                                <Form.Item valuePropName={'checked'} label={'Disabled'} name={'adminDisabled'}>
                                    <Switch/>
                                </Form.Item>
                                <Form.Item name={'tempo'}>
                                    <Select placeholder="Tempos">
                                        <Option value={''} key={'All'}>All</Option>
                                        {this.state.categories.tempos ? this.state.categories.tempos.map((tempo, index) =>
                                            <Option value={tempo.name} key={index}>{tempo.name}</Option>) : null}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Form.Item>
                                <Button htmlType="submit" type={'primary'}><SearchOutlined/>Search</Button>
                            </Form.Item>
                        </Row>
                    </Form>
                </Col>
                <Col lg={24}>

                    <Table tableLayout={"auto"} dataSource={this.props.state.songs.results} loading={this.state.loading}
                           rowKey={record => record.songId} columns={this.getColumns()} bordered
                           rowClassName={(record) => (record.isDeleted ? `deleted-row` : null)}
                           expandable={{
                               expandedRowRender: record => this.expandableContent(record),
                               rowExpandable: record => (record.songwriters && record.songwriters.length) || (record.publishers && record.publishers.length),
                           }}

                           pagination={{
                               showSizeChanger: false,
                               defaultPageSize: 20,
                               total: this.props.state.songs.pagination && this.props.state.songs.pagination.totalCount ? this.props.state.songs.pagination.totalCount : 0,
                               onChange: (e) => {
                                   this.filterHandler({...this.state.filters, start: (e * 20) - 20})
                               },
                               current: this.props.state.songs.pagination && this.props.state.songs.pagination.nextStart ? Math.ceil(this.props.state.songs.pagination.nextStart / 20) : this.props.state.songs.pagination ? Math.ceil(this.props.state.songs.pagination.totalCount / 20) : 0
                           }}/>
                    <Modal
                        width={1024}
                        title={"Edit song"}
                        visible={this.state.editsongVis}
                        onOk={() => this.songForm.current.submit()}
                        onCancel={this.handleCancelEdit}
                        confirmLoading={this.state.loading}
                        forceRender={true}

                    >
                        {this.state.song && this.state.editsongVis ?
                            <Form
                                labelAlign={'right'}
                                labelCol={{
                                    lg: {span: 3},
                                    sm: {span: 8},

                                }}
                                wrapperCol={{
                                    lg: {span: 21},
                                    sm: {span: 16},
                                }}
                                className={'non-margin-form'}
                                onFinish={this.handeEditSong}
                                initialValues={{
                                    Title: this.state.song.title,
                                    songwriters: this.state.song.songwriters,
                                    publishers: this.state.song.publishers,
                                    Genres: this.state.song.genres && this.state.song.genres.includes(', ') ? this.state.song.genres.split(', ') : this.state.song.genres === '' ? [] : this.state.song.genres,
                                    soundsLike: this.state.song.soundsLike && this.state.song.soundsLike.includes(', ') ? this.state.song.soundsLike.split(', ') : this.state.song.soundsLike === "" ? [] : this.state.song.soundsLike,
                                    Gender: this.state.song.gender ? this.state.song.gender : null,
                                    Tempo: this.state.song.tempo ? this.state.song.tempo : null,
                                    Moods: this.state.song.mood && this.state.song.mood.includes(', ') ? this.state.song.mood.split(', ') : this.state.song.mood === "" ? [] : this.state.song.mood,
                                    Rating: this.state.song.rating ? this.state.song.rating : null
                                }}
                                ref={this.songForm}
                            >
                                <Tabs defaultActiveKey="1" tabPosition={'top'} className={"dashboard-tabs"}>

                                    <TabPane tab={<span><SoundOutlined/> Song information</span>} key={'Userdata'}>
                                        <Form.Item name={'Title'} label={'Title'}>
                                            <Input placeholder={'Title'}/>
                                        </Form.Item>

                                        <Form.Item name={'Genres'} label={'Genres'}>
                                            <Select mode="multiple" placeholder="Genres">
                                                {this.state.categories.genres ? this.state.categories.genres.map((genr, index) =>
                                                    <Option value={genr.id} key={index}>{genr.name}</Option>) : null}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name={'Moods'} label={'Mood'}>
                                            <Select mode="multiple" placeholder="Mood">
                                                {this.state.categories.moods ? this.state.categories.moods.map((mood, index) =>
                                                    <Option value={mood.id} key={index}>{mood.name}</Option>) : null}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name={'soundsLike'} label={'Sounds Like'}>
                                            <Select mode="multiple" placeholder="Sounds Like">
                                                {this.state.categories.soundsLikes ? this.state.categories.soundsLikes.map((soundslike, index) =>
                                                    <Option value={soundslike.id}
                                                            key={index}>{soundslike.name}</Option>) : null}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name={'Gender'} label={'Gender'}>
                                            <Select placeholder="Gender">
                                                {this.state.categories.genders ? this.state.categories.genders.map((gender, index) =>
                                                    <Option value={gender.id}
                                                            key={index}>{gender.name}</Option>) : null}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name={'Tempo'} label={'Tempos'}>
                                            <Select placeholder="Tempos">
                                                {this.state.categories.tempos ? this.state.categories.tempos.map((tempo, index) =>
                                                    <Option value={tempo.id} key={index}>{tempo.name}</Option>) : null}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name={'Rating'} label={'Rank'}>
                                            <Select placeholder="Rank" initialvalues={this.state.song.rating}>
                                                {this.state.song.rating ? [1, 2, 3, 4, 5].map((item) =>
                                                    <Option value={item} key={item}>{item}</Option>) : null}
                                            </Select>
                                        </Form.Item>

                                    </TabPane>

                                    <TabPane tab={<span><FormOutlined/> Songwriters</span>} key={'songs'}>
                                        <Form.List name="songwriters">
                                            {(fields, {add, remove}) => {
                                                return (
                                                    <div>
                                                        {fields.map(field => (
                                                            <Space key={field.key}
                                                                   style={{display: 'flex', marginBottom: 8}}
                                                                   align="start">
                                                                <Form.Item
                                                                    labelCol={{
                                                                        lg: {span: 24},
                                                                    }}
                                                                    wrapperCol={{
                                                                        lg: {span: 24},
                                                                    }}
                                                                    labelAlign={'left'}
                                                                    {...field}
                                                                    label={'Name'}
                                                                    name={[field.name, 'name']}
                                                                    fieldKey={[field.fieldKey, 'Name']}
                                                                    rules={[{
                                                                        required: true,
                                                                        message: 'Missing Name'
                                                                    }]}
                                                                >
                                                                    <Input placeholder="Name"/>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    labelCol={{
                                                                        lg: {span: 24},
                                                                    }}
                                                                    wrapperCol={{
                                                                        lg: {span: 24},
                                                                    }}
                                                                    labelAlign={'left'}
                                                                    {...field}
                                                                    label={'PRO Name'}
                                                                    name={[field.name, 'proid']}
                                                                    fieldKey={[field.fieldKey, 'Proid']}
                                                                    rules={[{
                                                                        required: true,
                                                                        message: 'Missing Proid'
                                                                    }]}
                                                                >
                                                                    <Input placeholder="PRO Name"/>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    labelCol={{
                                                                        lg: {span: 24},
                                                                    }}
                                                                    wrapperCol={{
                                                                        lg: {span: 24},
                                                                    }}
                                                                    labelAlign={'left'}
                                                                    label={'IPI Number'}
                                                                    {...field}
                                                                    name={[field.name, 'ipiid']}
                                                                    fieldKey={[field.fieldKey, 'Ipiid']}
                                                                    rules={[{
                                                                        required: true,
                                                                        message: 'Missing Ipiid'
                                                                    }]}
                                                                >
                                                                    <Input placeholder="IPI Number"/>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    labelCol={{
                                                                        lg: {span: 24},
                                                                    }}
                                                                    wrapperCol={{
                                                                        lg: {span: 24},
                                                                    }}
                                                                    labelAlign={'left'}
                                                                    label={'Share'}
                                                                    {...field}
                                                                    name={[field.name, 'share']}
                                                                    fieldKey={[field.fieldKey, 'Share']}
                                                                >
                                                                    <Input addonAfter={'%'} placeholder="Share"/>
                                                                </Form.Item>

                                                                {fields.length !== 1 ?
                                                                    <MinusCircleOutlined className={'person-remove-key'}
                                                                                         onClick={() => {
                                                                                             remove(field.name);
                                                                                         }}
                                                                    /> : null}
                                                            </Space>
                                                        ))}

                                                        <Form.Item>
                                                            <Button
                                                                type="dashed"
                                                                onClick={() => {
                                                                    add();
                                                                }}
                                                                block
                                                            >
                                                                <PlusOutlined/> Add songwriter
                                                            </Button>
                                                        </Form.Item>
                                                    </div>
                                                );
                                            }}
                                        </Form.List>
                                    </TabPane>

                                    <TabPane tab={<span><KeyOutlined/> Publishers</span>} key={'userPass'}>
                                        <Form.List name="publishers">
                                            {(fields, {add, remove}) => {
                                                return (
                                                    <div>
                                                        {fields.map(field => (
                                                            <Space key={field.key}
                                                                   style={{display: 'flex', marginBottom: 8}}
                                                                   align="start">
                                                                <Form.Item
                                                                    labelCol={{
                                                                        lg: {span: 24},
                                                                    }}
                                                                    wrapperCol={{
                                                                        lg: {span: 24},
                                                                    }}
                                                                    labelAlign={'left'}
                                                                    label={'Name'}
                                                                    {...field}
                                                                    name={[field.name, 'name']}
                                                                    fieldKey={[field.fieldKey, 'Name']}
                                                                    rules={[{
                                                                        required: true,
                                                                        message: 'Missing Name'
                                                                    }]}
                                                                >
                                                                    <Input placeholder="Name"/>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    labelCol={{
                                                                        lg: {span: 24},
                                                                    }}
                                                                    wrapperCol={{
                                                                        lg: {span: 24},
                                                                    }}
                                                                    labelAlign={'left'}
                                                                    label={'PRO Name'}
                                                                    {...field}
                                                                    name={[field.name, 'proid']}
                                                                    fieldKey={[field.fieldKey, 'Proid']}
                                                                    rules={[{
                                                                        required: true,
                                                                        message: 'Missing Proid'
                                                                    }]}
                                                                >
                                                                    <Input placeholder="PRO Name"/>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    labelCol={{
                                                                        lg: {span: 24},
                                                                    }}
                                                                    wrapperCol={{
                                                                        lg: {span: 24},
                                                                    }}
                                                                    labelAlign={'left'}
                                                                    label={'IPI Number'}
                                                                    {...field}
                                                                    name={[field.name, 'ipiid']}
                                                                    fieldKey={[field.fieldKey, 'Ipiid']}
                                                                    rules={[{
                                                                        required: true,
                                                                        message: 'Missing Ipiid'
                                                                    }]}
                                                                >
                                                                    <Input placeholder="IPI Number"/>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    labelCol={{
                                                                        lg: {span: 24},
                                                                    }}
                                                                    wrapperCol={{
                                                                        lg: {span: 24},
                                                                    }}
                                                                    labelAlign={'left'}
                                                                    label={'Share'}
                                                                    {...field}
                                                                    name={[field.name, 'share']}
                                                                    fieldKey={[field.fieldKey, 'Share']}

                                                                >
                                                                    <Input addonAfter={'%'} placeholder="Share"/>
                                                                </Form.Item>

                                                                {fields.length !== 1 ? <MinusCircleOutlined
                                                                    className={'person-remove-key'}
                                                                    onClick={() => {
                                                                        remove(field.name);
                                                                    }}
                                                                /> : null}
                                                            </Space>
                                                        ))}

                                                        <Form.Item>
                                                            <Button
                                                                type="dashed"
                                                                onClick={() => {
                                                                    add();
                                                                }}
                                                                block
                                                            >
                                                                <PlusOutlined/> Add Publisher</Button>
                                                        </Form.Item>
                                                    </div>
                                                );
                                            }}
                                        </Form.List>
                                    </TabPane>
                                    <TabPane tab={<span><SoundOutlined/> Lyrics</span>} key={'Lyrics'}>
                                        <Editor
                                            value={this.state.song.lyrics}
                                            onEditorChange={(value) => this.setState({
                                                song: {...this.state.song, lyrics: value}
                                            })}
                                            initialValue={this.state.song.lyrics}
                                            apiKey='nw78l97emf6bnhtjf87qpiuh5ep10cq34kgmuccmjvzqs524'
                                            name={'lyrics'}
                                            init={{
                                                height: 400,
                                                menubar: false,
                                                placeholder: "Song Lyrics",
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                ],
                                                toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                                            }}
                                        />
                                    </TabPane>
                                </Tabs>
                            </Form> : null}
                    </Modal>
                    <Modal
                        title={"Note"}
                        visible={this.state.noteModal}
                        onOk={() => this.form.current.submit()}
                        onCancel={this.handleCloseNote}
                        confirmLoading={this.state.loading}
                    >
                        {this.state.song?.songId || this.state.noteModal ?
                            <Form
                                onFinish={this.handleCreateNote}
                                initialValues={{
                                    note: this.state.note,
                                }}
                                ref={this.form}
                            >
                                <Form.Item
                                    label="Note"
                                    name="note"
                                >
                                    <TextArea/>
                                </Form.Item>
                            </Form> : null}
                    </Modal>
                </Col>
            </Row>
        );
    }
}

export default connect(
    state => ({
        state
    }),
    dispatch => ({
        onAddNote: (song) => {
            dispatch({
                type: "ON_ADD_NOTE",
                payload: song
            })
        },
        onFetchSongs: (songs) => {
            dispatch({
                type: "SONGS",
                payload: songs
            })
        },
        onRemoveSong: (songs) => {
            dispatch({
                type: "REMOVE_SONGS",
                payload: songs
            })
        },
        logOut: () => {
            dispatch({
                type: "LOGOUT",
            })
        }
    })
)(SongsTab);

import React, {Component} from "react";
import {
    Button,
    Col,
    Form,
    Input,
    Row,
    Table,
    notification,
    Modal,
    Typography,

} from "antd";
import api from "../../api";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {PlusCircleOutlined} from "@ant-design/icons";
const {Title} = Typography;
class GenresTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addGenre:false,
            genres:{},
            loading:false,
        }
        this.genresForm=React.createRef();
    }


    componentDidMount() {
        this.setState({
            loading:true
        })
        fetch(api.Song.Categories.url, {
            headers: {
                'cache-control':'no-cache',
                'Authorization': this.props.state.auth.accessToken
            },
            method:api.Song.Categories.method,
        })
            .then(response => {
                if (response.ok){
                    response.json().then(response => {
                        this.setState({
                            genres: response.genres,
                            loading: false,
                            addGenre: false,
                        })
                    })
                }else if(response.status===401){
                    this.props.logOut()
                    this.props.history.push('/')
                } else {
                    this.setState({
                        loading: false,
                        addGenre: false,
                    })
                    notification.error({
                        message: 'Error',
                        description: 'Please contact administrator.'
                    })
                }
            })
    }

    handleAddGere (values){
        fetch(api.Categories.Genre.url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'cache-control':'no-cache',
                'Authorization': this.props.state.auth.accessToken
            },
            method:api.Categories.Genre.method,
            body:JSON.stringify(values)
        })
            .then(response => {
                if (response.ok){
                    notification.info({
                        message: 'Done',
                        description: 'Genre is added'
                    })
                    this.componentDidMount();
                }else{
                    notification.error({
                        message: 'Error',
                        description: 'Somethings wrong'
                    })
                }
            })

    }
    render() {
        const columns = [
            {
                title: 'Genre',
                dataIndex: 'name',
                key: 'soundslike',
            },
        ];
        return (
            <Row className={'tab-content'}>

                <Col  lg={24}>
                    <Title level={4}>Genres</Title>
                    <Button onClick={()=>this.setState({
                        addGenre:true
                    })} style={{marginBottom: '10px'}}> <PlusCircleOutlined />Create New Genre</Button>
                    <Table dataSource={this.state.genres[0]?this.state.genres:[]} rowKey={record => record.email} columns={columns} loading={this.state.loading} size={"small"}
                    />

                </Col>
                <Modal
                    width={400}
                    title={"Add Genre"}
                    visible={this.state.addGenre}
                    onOk={() => this.genresForm.current.submit()}
                    onCancel={() =>{this.setState({addGenre:false});  this.genresForm.current.resetFields()}}
                    confirmLoading={this.state.loading}

                >
                    <Form
                        onFinish={this.handleAddGere.bind(this)}
                        ref={this.genresForm}
                    >
                        <Form.Item
                            label="Genre"
                            name="Name"
                            rules={[{required: true, message: 'Please input Name!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Form>
                </Modal>
            </Row>
        );
    }
}
export default connect(
    state => ({
        state
    }),
    dispatch => ({
        logOut: () => {
            dispatch({
                type: "LOGOUT",
            })
        }
    }))(withRouter(GenresTab));

import React, {Component} from "react";
import {
    Button,
    Col, Form, Input, Layout, notification,
    Row, Select,
    Switch, Tabs,
} from "antd";
import {Editor} from '@tinymce/tinymce-react';
import {
    SaveOutlined,
    IdcardOutlined,
    ToolOutlined,
    KeyOutlined,
    BookOutlined,
    DollarCircleOutlined
} from '@ant-design/icons';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import api from "../../../api";
import UserSubscriptions from "./Fragments/UserSubscriptions";
import UserOrders from "./Fragments/UserOrders";
const { Content} = Layout;
const {TabPane} = Tabs;
const { Option } = Select;

const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};
class User extends Component {
    constructor(props) {
        super(props);
        this.state= {
            vendorBio:null,
            loading:false,
        }
        this.form=React.createRef();
        this.passform=React.createRef();
    }
    componentDidMount() {
        this.setState({
            vendorBio:this.props.state.user.vendorBio,
            userNewType:this.props.state.user.type
        })
    }

    handleCreateUser = formData => {
        this.setState({
            loading: true,
        });
        if(this.props.state.user.email){
            formData['UserId']=this.props.state.user.userId
        }
        if(this.state.vendorBio){
            formData['vendorBio'] =this.state.vendorBio
        }else if(this.props.state.user.vendorBio){
            formData['vendorBio'] =this.props.state.user.vendorBio
        }
        let data = {...this.props.state.user,...formData,}
        data = JSON.stringify(data)
        fetch(api.User[this.props.state.user.email?'Update':'Create'].url+(this.props.state.user.email?this.props.state.user.userId:''), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.state.auth.accessToken
            },
            method: api.User[this.props.state.user.email?'Update':'Create'].method,
            body: data,
        }).then(response =>{
            if(response.ok){
                this.props.onUpdateUserData(formData);
                notification.info({
                    message: 'Done',
                    description: 'Successfully Updated'
                })
            }else{
                response.json().then(response=>{
                    notification.error({
                        message: 'Error '+response.StatusCode,
                        description: response.Message
                    })
                })
            }
            this.setState({
                loading:false,
                userDat:{},
                createFormVisibility: false,
                action:'create',
            })
        }).catch((error) => {
            notification.error({
                message: 'Error',
                description: 'Please contact administrator.'
            })
        });

    }
    handleConfirmBlur = e => {
        const value = e.target.value;
        this.setState({confirmPass: this.state.confirmPass || !!value});
    };
    validateToNextPassword = (rule, value, callback) => {
        const form = this.passform.current;
        if (value && this.state.confirmPass) {
            form.validateFields(['passSubmit'], {force: true});
        }
        callback();
    };
    compareToFirstPassword = (rule, value, callback) => {
        const form = this.passform.current;
        if ((value && value !== form.getFieldValue('password')) || (form.getFieldValue('password') && !value)) {
            callback('Your passwords doesnt match');
        } else {
            callback();
        }
    };
    handleChangePass =formData=>{
        this.setState({loading:true})
        const data = JSON.stringify({
            UserId:this.props.state.user.userId,
            NewPassword:formData.password,
        });
        fetch(api.User.UpdatePassword.url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.state.auth.accessToken
            },
            method: api.User.UpdatePassword.method,
            body: data,
        }).then(response=>{
            notification.info({
                message: 'Done',
                description: 'Your password has been changed'
            })
            this.setState({
                passModal:false,
                loading:false,
                userDat:{},
            })
        })
    }
    render() {
        return (
            <Layout className="layout">
                <Content>
                    <Button onClick={()=>this.props.history.goBack()} >Back</Button>
                    <Button loading={this.state.loading} onClick={()=>this.form.current.submit()} style={{margin:'0 20px'}} type="primary" icon={<SaveOutlined />}>{this.props.state.user.email?'Save':'Create User'}</Button>
                    <Form
                        {...layout}
                        onFinish={this.handleCreateUser}
                        initialValues={{
                            firstName:this.props.state.user.firstName,
                            lastName:this.props.state.user.lastName,
                            city:this.props.state.user.city,
                            email:this.props.state.user.email,
                            artistName:this.props.state.user.artistName,
                            vendorName:this.props.state.user.vendorName,
                            type:this.props.state.user.type?this.props.state.user.type:'Performer',
                            country:this.props.state.user.country,
                            isMasterAdmin:this.props.state.user.isMasterAdmin,
                            verified:this.props.state.user.verified,
                            disabled:this.props.state.user.disabled,
                            vendorBio:this.props.state.user.vendorBio?this.props.state.user.vendorBio:'',
                        }}
                        ref={this.form}>
                    <Tabs defaultActiveKey="1"  tabPosition={'top'} className={"dashboard-tabs"}>

                        <TabPane forceRender={true} tab={<span><IdcardOutlined /> User information</span>} key={'Userdata'}>
                            <Row>
                                <Col lg={12}>
                                    <Form.Item
                                        label="User Type"
                                        name="type"
                                        rules={[{required: true, message: 'Please choose user type!'}]}
                                    >
                                        <Select onSelect={(val)=>this.setState({userNewType:val})} >
                                            <Option value="Performer">Performer</Option>
                                            <Option value="Provider">Provider</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="First name"
                                        name="firstName"
                                        rules={[{required: true, message: 'Please input first name!'}]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Last name"
                                        name="lastName"
                                        rules={[{required: true, message: 'Please input last name!'}]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                    {this.state.userNewType==='Provider'?
                                        <Form.Item
                                            label="Publisher Name"
                                            name="vendorName"
                                        >
                                            <Input/>
                                        </Form.Item>  :
                                    <Form.Item
                                        label="Artist Name"
                                        name="artistName"
                                    >
                                        <Input/>
                                    </Form.Item>}

                                    <Form.Item
                                        label="Country"
                                        name="country"
                                        rules={[{required: this.state.formAction==='create', message: 'Please input country!'}]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item
                                        label="City"
                                        name="city"
                                        rules={[{required: this.state.formAction==='create', message: 'Please input city!'}]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[{required: true, message: 'Please input email!', type: "email"}]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    {!this.props.state.user.email?
                                    <Form.Item
                                        label="Password"
                                        name="password"
                                        rules={[
                                            {
                                                required: true, message: 'Please input Password!'
                                            },
                                            {
                                            min: 4,
                                            message: 'password must be at least 4 characters',
                                            },
                                        ]}
                                    >
                                        <Input.Password  placeholder="input password" />
                                    </Form.Item>:null}
                                </Col>
                                <Col lg={12}>

                                    {this.state.userNewType==='Provider'?
                                        <div>
                                        <Editor

                                        value={this.state.vendorBio && this.state.vendorBio!==''?this.state.vendorBio:null}
                                        onEditorChange={(value) => this.setState({
                                            vendorBio:value
                                            })}
                                        initialValue={this.props.state.user.vendorBio}
                                        apiKey='nw78l97emf6bnhtjf87qpiuh5ep10cq34kgmuccmjvzqs524'
                                        name={'lyrics'}
                                        init={{
                                            height: 365,
                                            placeholder: "Bio",
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                                        }}
                                    /></div>:null
                                    }
                                </Col>
                            </Row>
                        </TabPane>

                        <TabPane forceRender={true} tab={<span><ToolOutlined /> User settings</span>} key={'songs'}>
                            <Row>
                                <Col lg={12}>
                                    <Form.Item
                                        label="Is Master Admin"
                                        name="isMasterAdmin"
                                        valuePropName={'checked'}
                                    >
                                        <Switch disabled={!!this.props.state.user.email} />
                                    </Form.Item>
                                    <Form.Item
                                        label="Email Verified"
                                        name="verified"
                                        valuePropName={'checked'}
                                    >
                                        <Switch />
                                    </Form.Item>
                                    <Form.Item
                                        label="Disabled"
                                        name="disabled"
                                        valuePropName={'checked'}
                                    >
                                        <Switch  />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </TabPane>

                        <TabPane  tab={<span><KeyOutlined /> Password</span>} key={'userPass'}>
                            <Form {...layout} ref={this.passform} onFinish={this.handleChangePass}>
                            <Row>
                                <Col lg={12}>
                                    <Row>
                                        <Col lg={24}>
                                            <Form.Item
                                                label="Password"
                                                name="password"

                                                rules={[
                                                    {
                                                        required: true, message: 'Please input Password!'
                                                    },
                                                    {
                                                        validator: this.validateToNextPassword,
                                                    },
                                                    {
                                                        min: 4,
                                                        message: 'password must be at least 4 characters',
                                                    },
                                                ]}
                                            >
                                                <Input.Password placeholder="input password" />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={24}>
                                            <Form.Item
                                                label="Submit password"
                                                name="passSubmit"
                                                rules={[
                                                    {required: true, message: 'Please submit your password!'},
                                                    {
                                                        validator: this.compareToFirstPassword,
                                                    },
                                                ]}
                                            >
                                                <Input.Password onBlur={this.handleConfirmBlur} placeholder="Submit password" />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={{span:7,offset:17}}>
                                        <Button htmlType={'submit'} style={{margin:'0 20px'}} type="primary" icon={<KeyOutlined />}>Change Password</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            </Form>
                        </TabPane>
                        {this.props.state.user?.type==='Provider'?<TabPane  tab={<span><BookOutlined /> Subscriptions</span>} key={'subscriptions'}>
                            <UserSubscriptions/>
                        </TabPane>:null}
                        <TabPane  tab={<span><DollarCircleOutlined /> Orders</span>} key={'orders'}>
                            <UserOrders/>
                        </TabPane>
                    </Tabs>
                    </Form>
                </Content>
            </Layout>
        );
    }
}

export default connect(
    state => ({
        state
    }),
    dispatch => ({
        onUpdateUserData: userData => {
            dispatch({
                type: "UPDATE_USER_DATA",
                payload:userData
            })
        },
        logOut: () => {
            dispatch({
                type: "LOGOUT",
            })
        }
    }))(withRouter(User));

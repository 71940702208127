import React, {Component} from "react";
import {BrowserRouter, Switch, Route} from "react-router-dom";
import AppLayout from "./Components/AppLayout";
import Login from "./Components/Login";
import User from "./Components/Tabs/Users/User";

class Routes extends Component {

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={Login}/>
                    <Route exact path="/dashboard/:tab?" component={AppLayout}/>
                    <Route exact path="/user/:id?" component={User}/>
                </Switch>
            </BrowserRouter>
        )
    }
}

export default Routes
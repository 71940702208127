import React, {Component} from "react";
import {
    Button,
    Col,
    Form,
    Input,
    Row,
    Table,
    notification,
    Modal, Typography,
} from "antd";
import api from "../../api";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {CopyOutlined, PlusCircleOutlined} from "@ant-design/icons";
const {Title} = Typography;
class SoundsLikeTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addAffiliate:false,
            affiliates:[],
            loading:false,
            affiliate:{}

        }
        this.affiliateForm = React.createRef();
    }


    componentDidMount() {
        this.setState({
            loading:true
        })
        fetch(api.Affiliate.Get.url, {
            headers: {
                'cache-control':'no-cache',
                'Authorization': this.props.state.auth.accessToken
            },
            method:api.Affiliate.Get.method,
        })
            .then(response => response.json()).then(response=>{
            this.setState({
                affiliates:response,
                loading:false,
                addAffiliate:false,
            })

        })
    }

    handleAddAffilate =(values)=>{
        fetch(api.Affiliate.Create.url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.state.auth.accessToken
            },
            method:api.Affiliate.Create.method,
            body:JSON.stringify(values)
        }).then(response => {
                if (response.ok){
                    notification.info({
                        message: 'Done',
                        description: 'Affiliate Created'
                    })
                    this.componentDidMount();
                }else{
                    notification.error({
                        message: 'Error',
                        description: 'Somethings wrong'
                    })
                }
            })

    }

     copyToClipboard = str => {
        const el = document.createElement('textarea');
         el.value = encodeURI(str);
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
         notification.info({
             message: 'Done',
             description: 'Link copied'
         })
    };
    render() {
        const columns = [
            {
                title: 'Name',
                align:'center',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Email',
                align:'center',
                dataIndex: 'email',
                key: 'email',
            },
            {
                title: 'Token',
                align:'center',
                dataIndex: 'token',
                key: 'token',
            },
            {
                title: 'URL',
                align:'center',
                dataIndex: 'url',
                key: 'url',
                children:[
                    {
                        title: 'Content Partner',
                        align: 'center',
                        dataIndex: 'contprov',
                        key: 'contprov',
                        width:100,
                        render:(_,record)=> <Button icon={<CopyOutlined/>} onClick={()=>this.copyToClipboard(`${process.env.REACT_APP_WEBSITE_URL}/BecomeAContentPartner?token=${record.token}&name=${record.name}`)}/>
                    },
                    {
                        title: 'Artist',
                        align: 'center',
                        dataIndex: 'artist',
                        key: 'artist',
                        width:100,
                        render:(_,record)=> <Button icon={<CopyOutlined/>} onClick={()=>this.copyToClipboard(`${process.env.REACT_APP_WEBSITE_URL}/shop?token=${record.token}&name=${record.name}`)}/>
                    },
                ]
            },
        ];
        return (
            <Row className={'tab-content'}>
                <Col lg={24}>
                    <Title level={4}>Affiliates</Title>
                    <Button onClick={()=>this.setState({
                        addAffiliate:true,
                        affiliate: {}
                    })} style={{marginBottom: '10px'}}><PlusCircleOutlined />Create New Affiliate</Button>
                </Col>
                <Modal
                    width={400}
                    title={"Affiliate"}
                    visible={this.state.addAffiliate}
                    onOk={() => this.affiliateForm.current.submit()}
                    onCancel={() =>{this.setState({addAffiliate:false}); this.affiliateForm.current.resetFields()}}
                    confirmLoading={this.state.loading}
                >
                    {this.state.affiliate && this.state.addAffiliate?  <Form
                        labelCol={{lg:6}}
                        wrapperCol={{lg:18}}
                        onFinish={this.handleAddAffilate}
                        ref={this.affiliateForm}
                        initialValues={this.state.affiliate}
                    >
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{required: true, message: 'Please input Name!'}]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{
                                type: 'email',
                                required: true,
                                message: 'Please input Email!'
                            }]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="Token"
                            name="token"
                            rules={[{
                                required: true,
                                message: 'Please input Token!'
                            }]}>
                            <Input/>
                        </Form.Item>
                    </Form>:null}
                </Modal>
                <Col lg={24}>
                    <Table bordered dataSource={this.state.affiliates} rowKey={record => record.token} columns={columns} loading={this.state.loading} size={"small"}
                    />
                </Col>

            </Row>
        );
    }
}
export default connect(
    state => ({
        state
    }), () => ({}))(withRouter(SoundsLikeTab));

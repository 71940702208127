import { combineReducers } from 'redux';
import auth from './auth'
import users from './users'
import songs from "./songs";
import user from "./user";
import coupons from "./coupons";


export default combineReducers({
    auth,
    users,
    user,
    songs,
    coupons,

})

import React, {Component} from "react";
import {Button, Col, Form, Input, Row, Table, Typography, notification, DatePicker} from "antd";
import api from "../../api";
import {connect} from "react-redux";
import {LoginOutlined, PlayCircleOutlined, SearchOutlined} from "@ant-design/icons";
import {withRouter} from "react-router-dom";
import moment from "moment";
import AudioPlayer from "react-h5-audio-player";
import CloseSquareOutlined from "@ant-design/icons/lib/icons/CloseSquareOutlined";
const {Title} = Typography;
const {RangePicker} = DatePicker;


class ApplicantsTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            createFormVisibility: false,
            updateFormVisibility: false,
            passModal:false,
            loading: false,
            filters:{},
            userDat:{},
            playMusic:null,
            applicants:{},
        }
        this.form=React.createRef();
        this.player = React.createRef();
    }


    componentDidMount() {
        this.searchApplicants(new URLSearchParams(this.props.history.location.search))
    }
    searchApplicants = (params) => {
        if(params['date']){
            params['createdFrom'] =moment.utc(params.date[0]).format()
            params['createdTo'] =moment.utc(params.date[1]).format()
            delete params['date'];
        }
        if(params['submited']){
            params['submitedFrom'] =moment.utc(params.submited[0]).format()
            params['SubmitedTo'] =moment.utc(params.submited[1]).format()
            delete params['submited'];
        }
        let search;
        if(params){
            Object.keys(params).forEach(key => params[key] === undefined && delete params[key])
            search= new URLSearchParams(params).toString();
        }
        this.setState({
            loading: true,
            playMusic:null
        });
        this.player.current.audio.current.pause();
        this.props.history.push({
            search:params?`?${search}`:'',
            path:'/dashboard/users'
        })
        fetch(api.Applicant.Search.url+(params?`?${search}`:''), {
            headers: {
                'cache-control':'no-cache',
                'Authorization': this.props.state.auth.accessToken
            },
            method: api.Applicant.Search.method,
        })
            .then(response =>{
                if(response.ok){
                    response.json().then((response) => {
                        this.setState({
                            loading: false,
                            filters:params,
                            applicants:response
                        });
                    })
                }else if(response.status===401){
                    this.props.logOut()
                    this.props.history.push('/')
                } else {
                    notification.error({
                        message: 'Error',
                        description: 'Please contact administrator.'
                    })
                }
            })
    }
    expandableContent = (content) => {
        const cols = [
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: 'Play',
                dataIndex: 'trackLink',
                key: 'tracklink',
                render:item=><Button  onClick={(e) => {
                    if (this.state.playMusic) {
                        document.getElementsByClassName('current-playing')[0].classList.remove('current-playing')
                    }
                    e.currentTarget.classList.add('current-playing')
                    this.setState({
                        playMusic: item
                    })
                }}><PlayCircleOutlined/></Button>
            },
        ]
        return <Table pagination={false} title={() => <h4>Songs</h4>}
                  dataSource={content.applicantSongs}
                   loading={this.state.loading} rowKey={record => record.title} columns={cols}
                   bordered/>

    }
    appAction(type,applicantId){
        this.setState({
            loading:true,
            playMusic:null
        })
        this.player.current.audio.current.pause();
        fetch(api.Applicant[type].url+applicantId, {
            headers: {
                'Accept': 'application/json',
                'cache-control': 'no-cache',
                'Content-Type': 'application/json',
                'Authorization': this.props.state.auth.accessToken
            },
            method: api.Applicant[type].method,
        }).then(response =>{
            if(response.ok){
                notification.info({
                    message: 'Done',
                    description: 'Applicant is '+type+'ed'
                })
                this.searchApplicants(this.props.history.location.search)
            } else {
                notification.error({
                    message: 'Error',
                    description: 'Please contact administrator.'
                })
            }
            this.setState({
                loading:false
            })
        })
    }
    handleLoginUser = (userData) =>{
        fetch(api.User.getToken.url + userData.userId, {
            headers: {
                'cache-control': 'no-cache',
                'Authorization': this.props.state.auth.accessToken
            },
            method: api.User.getToken.method,
        }).then(response=>response.json()).then(response=>{
            window.open(`${process.env.REACT_APP_WEBSITE_URL}/autologin?token=${response.token}&userId=${response.userId}`);
        })

    }
    render() {
        const columns = [
            {
                title: 'Registration Date',
                dataIndex: 'createdDate',
                sorter: (a, b) => moment(a.createdDate).unix() - moment(b.createdDate).unix(),
                key: 'createdDate',
                fixed: 'left',
                render:item=>moment(item).format('YYYY-MM-DD')
            },
            {
                title: 'Submission Date',
                dataIndex: 'submitionDate',
                sorter: (a, b) => moment(a.submitionDate).unix() - moment(b.submitionDate).unix(),
                key: 'submitionDate',
                fixed: 'left',
                render:item=>item?moment(item).format('YYYY-MM-DD'):'Not submitted'
            },
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: (a, b) => a.name.localeCompare(b.name),
                key: 'name',
            },
            {
                title: 'Songwriter/Publisher Name',
                dataIndex: 'artistName',
                sorter: (a, b) => a.vendorName?a.vendorName.localeCompare(b.vendorName):a.artistName.localeCompare(b.artistName),
                key: 'artistName',
                render:(item,record)=>record.vendorName?record.vendorName:item
            },

            {
                title: 'Email',
                dataIndex: 'email',
                sorter: (a, b) => a.email.localeCompare(b.email),
                key: 'email',
                columnWidth: 10
            },


            {
                title: 'Actions',
                dataIndex: 'actions',
                fixed: 'right',
                align:'center',
                key: 'actions',
                render: (item,result)=><div style={{display:'flex',flexWrap:'nowrap'}}>
                    <Button style={{color:'green'}} type={'Link'}  onClick={()=>this.appAction('Approv',result.userId)}>Approve</Button>
                    <Button style={{color:'red'}} type={'Link'}  onClick={()=>this.appAction('Reject',result.userId)}>Decline</Button></div>
            },
            {
                title: 'Login',
                dataIndex: 'actions',
                fixed: 'right',
                align:'center',
                key: 'actions',
                render: (item, result) =>  <Button onClick={() => this.handleLoginUser(result)}><LoginOutlined /></Button>
            },
        ];
        return (
            <Row className={'tab-content'}>
                <div className={'PlayerContainer ' + (this.state.playMusic ? 'showplayer' : '')}>
                    <span className={'close-btn'} onClick={() => {
                        this.player.current.audio.current.pause();
                        if (this.state.playMusic) {
                            document.getElementsByClassName('current-playing')[0].classList.remove('current-playing')
                        }
                        this.setState({
                            playMusic: false,
                        })
                    }}><CloseSquareOutlined/></span>

                    <AudioPlayer ref={this.player} autoPlay={true}
                                 src={this.state.playMusic ? this.state.playMusic : undefined}/>
                </div>
                <Col lg={24}>

                    <Form  layout={'horizontal'} onFinish={this.searchApplicants}>
                        <Row className={'filters-row'}>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Title level={4}>Basic filters</Title>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={24} xs={24}>
                                <Form.Item name={'name'}>
                                    <Input placeholder="Name"/>
                                </Form.Item>
                                <Form.Item name={'email'}>
                                    <Input placeholder="email"/>
                                </Form.Item>
                            </Col>
                            <Col xl={6} lg={8} md={8} sm={24} xs={24}>
                                <Form.Item name="date"  rules={[ {
                                    type: 'array',
                                    message: 'Please select time!',
                                },]}>
                                    <RangePicker placeholder={['Registered From','Registered To']} format="YYYY-MM-DD" />
                                </Form.Item>
                                <Form.Item name="submited"  rules={[ {
                                    type: 'array',
                                    message: 'Please select time!',
                                },]}>
                                    <RangePicker placeholder={['Submitted From','Submitted To']} format="YYYY-MM-DD" />
                                </Form.Item>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} xs={24}>
                                <Button htmlType={'submit'} type="primary"><SearchOutlined />Search</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col  lg={24}>
                    <Table dataSource={this.state.applicants.results?this.state.applicants.results:[]} rowKey={record => record.email} columns={columns} loading={this.state.loading} size={"small"}
                           expandable={{
                               expandedRowRender: record => this.expandableContent(record),
                               rowExpandable: record => record.applicantSongs,
                           }}
                           pagination={{
                               defaultPageSize: 20,
                               showSizeChanger:false,
                               total:this.state.applicants.pagination&&this.state.applicants.pagination.totalCount?this.state.applicants.pagination.totalCount:0,
                               onChange:(e)=>{
                                   this.searchApplicants({...this.state.filters,start:(e*20)-20})
                               },
                               current: this.state.applicants.pagination&&this.state.applicants.pagination.nextStart?Math.ceil(this.state.applicants.pagination.nextStart / 20):this.state.applicants.pagination?.totalCount?Math.ceil(this.state.applicants.pagination.totalCount/20):1
                           }}
                    />

                </Col>
            </Row>
        );
    }
}
export default connect(
    state => ({
        state
    }),
    dispatch => ({
        logOut: () => {
            dispatch({
                type: "LOGOUT",
            })
        }
    })
)(withRouter(ApplicantsTab));

import React, {Component} from "react";
import {Button, Form, Input, Layout, notification} from "antd";
import {UserOutlined, LockOutlined, MailOutlined} from '@ant-design/icons';
import api from "../api";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

const {Content} = Layout;

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }


    componentDidMount() {
        if (this.props.state.auth && this.props.state.auth.accessToken) {
            this.props.history.push("/dashboard")
        }
    }

    handleSubmit = values => {
        const data = JSON.stringify(values)
        this.setState({
            loading: true,
        });
        fetch(api.Login.url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            method: api.Login.method,
            body: data,

        })
            .then(response => response.json())
            .then((response) => {
                if (response.userId) {
                    this.props.onLogin(response);
                    this.props.history.push("/dashboard")
                } else {
                    notification.error({
                        message: 'Error',
                        description: 'Wrong email or password.'
                    })
                    this.props.logOut();
                }
                this.setState({
                    loading: false,
                });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                });
                notification.error({
                    message: 'Error',
                    description: 'Please contact administrator.'
                })
            });
    };

    render() {

        return (

            <div className={"auth-form tiny-shadow"}>
                <Content>
                    <h2><UserOutlined/>Login</h2>
                    <Form onFinish={this.handleSubmit} className="login-form">
                        <Form.Item name={'Email'} rules={[{
                            required: true,
                            message: 'Please enter email address'
                        }]}>

                            <Input prefix={<MailOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                                   disabled={this.state.loading}
                                   placeholder="Email"/>
                        </Form.Item>
                        <Form.Item name={'Password'} rules={[{
                            required: true,
                            message: 'Please enter your password'
                        }]}>
                            <Input prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                                   type="password"
                                   disabled={this.state.loading}
                                   placeholder={"Password"}/>
                        </Form.Item>
                        <Button type="primary" icon={<UserOutlined/>} htmlType="submit" className="block"
                                loading={this.state.loading}>Login</Button>
                    </Form>
                </Content>
            </div>

        );
    }
}

export default connect(
    state => ({
        state
    }),
    dispatch => ({
        onLogin: (token) => {
            dispatch({
                type: "LOGIN",
                payload: token
            })
        },
        logOut: () => {
            dispatch({
                type: "LOGOUT",
            })
        }
}))(withRouter(Login));

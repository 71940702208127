import React, {Component} from "react";
import {Col, Layout, Menu, Row, Tabs, Dropdown} from "antd";
import UsersTab from "./Tabs/Users/UsersTab";
import SongsTab from "./Tabs/SongsTab";
import DashboardTab from "./Tabs/DashboardTab";
import {UserOutlined, LogoutOutlined,UserDeleteOutlined, DashboardOutlined, AudioOutlined,PercentageOutlined,SoundOutlined,DollarCircleOutlined} from "@ant-design/icons";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Coupons from "./Tabs/Coupons";
import SoundsLikeTab from "./Tabs/SoundsLikeTab";
import GenresTab from "./Tabs/GenresTab";
import SalesTab from "./Tabs/SalesTab";
import ApplicantsTab from "./Tabs/ApplicantsTab";
import Affiliates from "./Tabs/Affiliates";
import CancelledApplicants from "./Tabs/CancelledApplicants";

const {Header, Content, Footer} = Layout;
const {TabPane} = Tabs;

class AppLayout extends Component {

    handleUserMenuClick = (menu) => {
        switch (menu.key) {
            case "logout":
                this.props.logOut();
                this.props.history.push("/")
                break;
            case "me":
                //todo my account
                break;
            default:
                break;
        }
    }

    render() {
        const userMenu = (
            <Menu onClick={this.handleUserMenuClick}>
                <Menu.Item key="logout">
                    <LogoutOutlined/> Log Out
                </Menu.Item>
            </Menu>
        );
        return (
            <Layout className="layout">
                <Header style={{
                    background: '#fff',
                    boxShadow: "0 2px 8px #f0f1f2",
                    position: "fixed",
                    width: "100%",
                    zIndex: 9
                }}>
                    <Row>
                        <Col xs={15}>ROCKET SONGS</Col>
                        <Col xs={9} style={{textAlign: "right"}}>
                            <Dropdown.Button overlay={userMenu} icon={<UserOutlined/>}>
                                User
                            </Dropdown.Button>
                        </Col>
                    </Row>
                </Header>
                <Content style={{marginTop: 75}}>
                    <Tabs defaultActiveKey={this.props.match.params.tab ? this.props.match.params.tab : 'stats'}
                          onChange={(activeKey) => {
                              this.props.history.push(`/dashboard/${activeKey}`)
                          }} tabPosition={'left'} className={"dashboard-tabs"}>
                        <TabPane tab={<span><DashboardOutlined /> Dashboard</span>} key={'stats'}>
                            <DashboardTab/>
                        </TabPane>
                        <TabPane tab={<span><UserOutlined /> Users</span>} key={'users'}>
                            <UsersTab/>
                        </TabPane>
                        <TabPane tab={<span><UserOutlined /> Applicants</span>} key={'applicants'}>
                            <ApplicantsTab/>
                        </TabPane>
                        <TabPane tab={<span><UserDeleteOutlined /> Declined applicants</span>} key={'declined-applicants'}>
                            <CancelledApplicants/>
                        </TabPane>
                        <TabPane tab={<span><DollarCircleOutlined /> Affiliates</span>} key={'affiliates'}>
                            <Affiliates/>
                        </TabPane>
                        <TabPane tab={<span><AudioOutlined /> Songs</span>} key={'songs'}>
                            <SongsTab/>
                        </TabPane>
                        <TabPane tab={<span><PercentageOutlined /> Coupons</span>} key={'coupons'}>
                            <Coupons/>
                        </TabPane>
                        <TabPane tab={<span><SoundOutlined /> Sounds like</span>} key={'soundslike'}>
                            <SoundsLikeTab/>
                        </TabPane>
                        <TabPane tab={<span><SoundOutlined /> Genres</span>} key={'genres'}>
                            <GenresTab/>
                        </TabPane>
                        <TabPane tab={<span><DollarCircleOutlined /> Sales</span>} key={'sales'}>
                            <SalesTab/>
                        </TabPane>
                    </Tabs>
                </Content>
                <Footer style={{textAlign: 'center'}}>Rocket Songs ©2020</Footer>
            </Layout>
        );
    }
}

export default connect(
    state => ({
        state
    }),
    dispatch => ({
            logOut: () => {
                dispatch({
                    type: "LOGOUT",
                })
            }
        })
)(withRouter(AppLayout));

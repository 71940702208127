import React, {Component} from "react";
import {Button, Checkbox, Col, Form, Input, Row, Table, Typography, notification, DatePicker} from "antd";
import api from "../../../api";
import {connect} from "react-redux";
import {EditOutlined, PlusCircleOutlined, SearchOutlined,LoginOutlined} from "@ant-design/icons";
import {withRouter} from "react-router-dom";
import moment from "moment";

const {Title} = Typography;
const {RangePicker} = DatePicker;


class UsersTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            createFormVisibility: false,
            updateFormVisibility: false,
            passModal: false,
            loading: false,
            filters: {},
            userDat: {}
        }
        this.form = React.createRef();
    }


    componentDidMount() {
        this.searchUsers(new URLSearchParams(this.props.history.location.search))
    }

    showCreateForm = () => {
        this.props.onUserCreate();
        this.props.history.push('/user/new')

    }
    showEditUser = item => {
        this.props.onFetchUserData(item);
        this.props.history.push('/user/' + item.userId)
    }
    searchUsers = (params) => {
        if (params['date']) {
            params['createdFrom'] = moment.utc(params.date[0]).format()
            params['createdTo'] = moment.utc(params.date[1]).format()
            delete params['date'];
        }
        let search;
        if (params) {
            Object.keys(params).forEach(key => params[key] === undefined && delete params[key])
            search = new URLSearchParams(params).toString();
        }
        this.setState({
            loading: true,
        });
        this.props.history.push({
            search: params ? `?${search}` : '',
            path: '/dashboard/users'
        })
        fetch(api.User.Search.url + (params ? `?${search}` : ''), {
            headers: {
                'cache-control': 'no-cache',
                'Authorization': this.props.state.auth.accessToken
            },
            method: api.User.Search.method,
        })
            .then(response => {
                if (response.ok) {
                    response.json().then((response) => {
                        this.props.onFetchUsers(response)
                        this.setState({
                            loading: false,
                            filters: params,
                        });
                    })
                } else if (response.status === 401) {
                    this.props.logOut()
                    this.props.history.push('/')
                } else {
                    notification.error({
                        message: 'Error',
                        description: 'Please contact administrator.'
                    })
                }
            })
    }
    handleLoginUser = (userData) =>{
        fetch(api.User.getToken.url + userData.userId, {
            headers: {
                'cache-control': 'no-cache',
                'Authorization': this.props.state.auth.accessToken
            },
            method: api.User.getToken.method,
        }).then(response=>response.json()).then(response=>{
            window.open(`${process.env.REACT_APP_WEBSITE_URL}/autologin?token=${response.token}&userId=${response.userId}`);
        })

    }
    render() {
        const columns = [
            {
                title: 'Registration Date',
                dataIndex: 'createdDate',
                sorter: (a, b) => moment(a.createdDate).unix() - moment(b.createdDate).unix(),
                key: 'createdDate',
                fixed: 'left',
                render: item => moment(item).format('YYYY-MM-DD')
            },
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: (a, b) => a.name.localeCompare(b.name),
                key: 'name',
            },
            {
                title: 'Artist/Vendor Name',
                dataIndex: 'artistName',
                fixed: 'left',
                sorter: (a, b) => a.vendorName ? a.vendorName.localeCompare(b.vendorName) : a.artistName.localeCompare(b.artistName),
                key: 'artistName',
                render: (item, record) => record.vendorName ? record.vendorName : item
            },
            {
                title: 'City',
                dataIndex: 'city',
                sorter: (a, b) => a.city.localeCompare(b.city),
                key: 'city',
            },
            {
                title: 'Country',
                dataIndex: 'country',
                sorter: (a, b) => a.country.localeCompare(b.country),
                key: 'country',
            },
            {
                title: 'Email',
                dataIndex: 'email',
                sorter: (a, b) => a.email.localeCompare(b.email),
                key: 'email',
                columnWidth: 10
            },
            {
                title: 'Type',
                dataIndex: 'type',
                sorter: (a, b) => a.type.localeCompare(b.type),
                key: 'type',
            },
            {
                title: 'Subscription start',
                dataIndex: 'subsctiptionType',
                sorter: (a, b) => a.type.localeCompare(b.type),
                key: 'subsctiptionType',
                align:'center',
                render:(item,record)=>{
                    switch (item){
                        case 1:
                            return <div>Limited(Month)<br/> {moment(record.subscriptionStartDate).format('YYYY-MM-DD')}</div>;
                        case 2:
                            return <div>Limited(Year)<br/> {moment(record.subscriptionStartDate).format('YYYY-MM-DD')}</div>;
                        case 3:
                            return <div>Unlimited(Month)<br/> {moment(record.subscriptionStartDate).format('YYYY-MM-DD')}</div>;
                        case 4:
                            return <div>Unlimited(Year)<br/> {moment(record.subscriptionStartDate).format('YYYY-MM-DD')}</div>;
                        default:
                           return null
                    }


                }
            },
            {
                title: 'Verified',
                dataIndex: 'verified',
                sorter: (a, b) => (a.verified ? 'y' : 'n').localeCompare(b.verified ? 'y' : 'n'),
                key: 'verified',
                render: item => item ? 'Yes' : 'No'
            },
            {
                title: 'Disabled',
                dataIndex: 'disabled',
                sorter: (a, b) => (a.disabled ? 'y' : 'n').localeCompare(b.disabled ? 'y' : 'n'),
                key: 'disabled',
                render: item => item ? 'Yes' : 'No'
            },
            {
                title: 'Affiliate',
                dataIndex: 'affilateName',
                key: 'affilateName',
                sorter: (a, b) => (a.affilateName).localeCompare(b.affilateName),
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                fixed: 'right',
                key: 'actions',
                render: (item, result) => <div style={{display: 'flex'}}>
                    <Button onClick={() => this.showEditUser(result)}><EditOutlined/></Button>
                    <Button onClick={() => this.handleLoginUser(result)}><LoginOutlined /></Button>
                </div>
            },
        ];

        return (
            <Row className={'tab-content'}>
                <Col lg={24}>

                    <Form layout={'horizontal'} onFinish={this.searchUsers}>
                        <Row className={'filters-row'}>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Title level={4}>Basic filters</Title>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={24} xs={24}>
                                <Form.Item name={'name'}>
                                    <Input placeholder="Name"/>
                                </Form.Item>
                                <Form.Item name={'email'}>
                                    <Input placeholder="email"/>
                                </Form.Item>
                            </Col>
                            <Col xl={6} lg={8} md={8} sm={24} xs={24}>
                                <Form.Item name="date" rules={[{
                                    type: 'array',
                                    message: 'Please select time!',
                                },]}>
                                    <RangePicker placeholder={['Registered From', 'Registered To']}
                                                 format="YYYY-MM-DD"/>
                                </Form.Item>

                                <Form.Item name={'types'}>
                                    <Checkbox.Group>
                                        <Checkbox value="Performers">Performers</Checkbox>
                                        <Checkbox value="Providers">Providers</Checkbox>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} xs={24}>
                                <Button htmlType={'submit'} type="primary"><SearchOutlined/>Search</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col lg={24}>
                    <Button onClick={this.showCreateForm} style={{marginBottom: '10px'}}><PlusCircleOutlined/>Create new
                        user</Button>
                    <Table dataSource={this.props.state.users.results ? this.props.state.users.results : []}
                           rowKey={record => record.email} columns={columns} loading={this.state.loading} size={"small"}
                           pagination={{
                               showSizeChanger:false,
                               defaultPageSize: 20,
                               total: this.props.state.users.pagination && this.props.state.users.pagination.totalCount ? this.props.state.users.pagination.totalCount : 0,
                               onChange: (e) => {
                                   this.searchUsers({...this.state.filters, start: (e * 20) - 20})
                               },
                               current: this.props.state.users.pagination && this.props.state.users.pagination.nextStart ? Math.ceil(this.props.state.users.pagination.nextStart / 20) :this.props.state.users.pagination?.totalCount?Math.ceil(this.props.state.users.pagination.totalCount/20):1
                           }}
                    />

                </Col>
            </Row>
        );
    }
}

export default connect(
    state => ({
        state
    }),
    dispatch => ({
        onFetchUserData: (singleuser) => {
            dispatch({
                type: "FETCH_USER",
                payload: singleuser
            })
        },
        onUserCreate: () => {
            dispatch({
                type: "CLEAN_USER",
            })
        },
        onFetchUsers: (users) => {
            dispatch({
                type: "USERS",
                payload: users
            })
        },
        logOut: () => {
            dispatch({
                type: "LOGOUT",
            })
        }
    })
)(withRouter(UsersTab));

export default function user(state = {}, action){
    if(action.type === 'FETCH_USER'){
        return {
            ...action.payload,
        }
    }
    if(action.type === 'CLEAN_USER'){
        return {}
    }
    if(action.type === 'UPDATE_USER_DATA'){
        return {
            ...state,
            ...action.payload,
        }
    }
    if(action.type === 'LOGOUT'){
        return {}
    }

    return state;
}
import React, {Component} from "react";
import {
    Button,
    Col, DatePicker,
    Form,
    Input, InputNumber,
    Modal,
    notification,
    Row,
    Select, Switch,
    Table, Typography,

} from "antd";
import {EditOutlined, PlusCircleOutlined} from '@ant-design/icons';
import api from "../../api";
import {connect} from "react-redux";
import 'react-h5-audio-player/src/styles.scss'
import moment from 'moment';

const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};
const types = {
    '1': 'Dollars off total',
    '2': 'Dollars off item',
    '3': 'Percent off total',
    '4': 'Percent off item',
    '5': 'Percent off vendor',
    '6': 'Dollars off vendor',
}
const licenseTypes = {
    '0': 'Any',
    '1': 'Song',
    '4': 'Exclusive',
    '8': 'Stem',
}
const {Title} = Typography;

class Coupons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            coupon: {},
            editCouponVis: false,
            singleItemInput:false

        }
        this.form = React.createRef();
        this.couponForm = React.createRef();
    }

    getColumns = () => [
        {
            title: 'Expires',
            dataIndex: 'expiry',
            sorter: (a, b) => moment(a.expiry).unix() - moment(b.expiry).unix(),
            key: 'expiry',
            fixed: 'left',
            render: item => moment(item).format('YYYY-MM-DD')
        },
        {
            title: 'Code',
            dataIndex: 'code',
            sorter: (a, b) => a.code.localeCompare(b.code),
            fixed: 'left',
            key: 'code',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            sorter: (a, b) => types[a.type].localeCompare(types[b.type]),
            key: 'type',
            render: item => types[item]
        },
        {
            title: 'Product/Vendor Id',
            dataIndex: 'type',
            key: 'type',
            render: (item,record) => record.productId?record.productId:record.vendorId
        },
        {
            title: 'Product Type',
            dataIndex: 'licenseType',
            key: 'licenseType',
            render: item => {
                const keys = Object.keys(licenseTypes).filter(key => {
                    return item & key
                })
                let licTypeVal = '';
                keys.map(key => {
                        licTypeVal = licTypeVal + licenseTypes[key] + ',';
                        return null;
                    }
                )
                if (licTypeVal === '') {
                    return 'Any'
                }
                return licTypeVal;
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            sorter: (a, b) => a.amount - b.amount,
            key: 'amount',
        },
        {
            title: 'One Per Customer',
            dataIndex: 'onePerCustomer',
            sorter: (a, b) => (a.onePerCustomer ? 'y' : 'n').localeCompare(b.onePerCustomer ? 'y' : 'n'),
            key: 'onePerCustomer',
            render: item => item ? 'Yes' : 'No'
        },
        {
            title: 'Disabled',
            dataIndex: 'disabled',
            sorter: (a, b) => (a.disabled ? 'y' : 'n').localeCompare(b.disabled ? 'y' : 'n'),
            key: 'disabled',
            render: item => item ? 'Yes' : 'No'
        },

        {
            title: 'Actions',
            key: 'operation',
            fixed: 'right',
            render: (item, result) => {
                const data = {...result};
                return <div style={{display: 'flex'}}><Button
                    onClick={() => this.openEditModal(data)}><EditOutlined/></Button></div>
            }

        },
    ];

    openEditModal = (record) => {
        if (record.licenseType === 0) {
            record.licenseType = ['0']
        } else {
            record.licenseType = Object.keys(licenseTypes).filter(key => {
                return record.licenseType & key
            })
        }
        this.setState({
            editCouponVis: true,
            coupon: record
        })
    }
    handleCancelEdit = () => {
        this.setState({
            editCouponVis: false,
            coupon: {},
            singleItemInput:false,
        })
    }

    componentDidMount() {
        this.setState({
            loading: true,
        });
        fetch(`${api.Coupon.Get.url}/0`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'cache-control': 'no-cache',
                'Authorization': this.props.state.auth.accessToken
            },
            method: api.Coupon.Get.method,
        }).then(response => {
            if (response.ok) {
                response.json().then((response) => {
                    this.props.onFetchCoupons(response)
                    this.setState({
                        loading: false,
                    });
                })
            } else if(response.status===401){
                this.props.logOut()
                this.props.history.push('/')
            } else {
                notification.error({
                    message: 'Error',
                    description: 'Please contact administrator.'
                })
            }
        })

    }

    filterHandler = formData => {
        this.setState({loading: true})
        fetch(api.Coupon.Get.url + `/${formData.start}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'cache-control':'no-cache',
                'Authorization': this.props.state.auth.accessToken
            },
            method: api.Coupon.Get.method,
        }).then(response => response.json()).then(response => {
            this.props.onFetchCoupons(response)
            this.setState({loading: false, filters: formData})
        });
    }
    handeEditCoupon = values => {
        this.setState({loading: true})
        values.couponId = this.state.coupon.couponId;
        if (Array.isArray(values.licenseType)) {
            values.licenseType = values.licenseType.reduce(function (a, b) {
                return a | b;
            }, 0);
        }
        const data = JSON.stringify(values)
        fetch(`${api.Coupon[this.state.coupon.couponId ? 'Update' : 'Create'].url}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.state.auth.accessToken
            },
            method: api.Coupon[this.state.coupon.couponId ? 'Update' : 'Create'].method,
            body: data
        }).then(response => response.json()).then(response => {
            if (this.state.coupon.couponId) {
                this.props.onCouponEdit(response)
            } else {
                this.props.onCouponCreate(response)
            }

            this.setState({loading: false, singleItemInput:false, editCouponVis: false})
        })
    }

    render() {
        const name = types[this.state.singleItemInput!==0 && this.state.coupon.type?this.state.coupon.type:this.state.singleItemInput?this.state.singleItemInput:1].includes('vendor')?'Vendor':'Product';
        const {Option} = Select;
        return (
            <Row className={'tab-content'}>

                <Col lg={24}>
                    <Title level={4}>Coupons</Title>
                    <Button onClick={() => this.openEditModal({})} style={{marginBottom: '10px'}}><PlusCircleOutlined/>Create
                        Coupon</Button>
                    <Table dataSource={this.props.state.coupons.results} loading={this.state.loading}
                           rowKey={record => record.code} columns={this.getColumns()} pagination={{
                        defaultPageSize: 10,
                        showSizeChanger:false,
                        total: this.props.state.coupons.pagination && this.props.state.coupons.pagination.totalCount ? this.props.state.coupons.pagination.totalCount : 0,
                        onChange: (e) => {
                            this.filterHandler({start: (e * 10) - 10})
                        },
                        current: this.props.state.coupons.pagination && this.props.state.coupons.pagination.nextStart ? (this.props.state.coupons.pagination.nextStart / 10) : this.props.state.coupons.pagination ? Math.ceil(this.props.state.coupons.pagination.totalCount / 10) : 0
                    }}/>
                    <Modal
                        width={680}
                        title={"Edit Coupon"}
                        visible={this.state.editCouponVis}
                        onOk={() => this.couponForm.current.submit()}
                        onCancel={this.handleCancelEdit}
                        confirmLoading={this.state.loading}

                    >
                        {this.state.coupon && this.state.editCouponVis ?
                            <Form
                                onFinish={this.handeEditCoupon}
                                initialValues={{
                                    code: this.state.coupon.code,
                                    description: this.state.coupon.description,
                                    type: this.state.coupon.type,
                                    licenseType: this.state.coupon.licenseType,
                                    amount: this.state.coupon.amount,
                                    minimum: this.state.coupon.minimum,
                                    onePerCustomer: this.state.coupon.onePerCustomer,
                                    disabled: this.state.coupon.disabled,
                                    ProductId: this.state.coupon.productId,
                                    VendorId: this.state.coupon.vendorId,
                                    numberOfCoupons: this.state.coupon.numberOfCoupons,
                                    expiry: moment(this.state.coupon.expiry),
                                }}
                                {...layout}
                                ref={this.couponForm}
                            >
                                <Form.Item
                                    label="Code"
                                    name="code"
                                    rules={[{required: true, message: 'Please input Code!'}]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="Description"
                                    name="description"
                                    rules={[{required: true, message: 'Please input Description!'}]}
                                >
                                    <Input.TextArea/>
                                </Form.Item>
                                <Form.Item
                                    label="Type"
                                    name="type"
                                    rules={[{required: true, message: 'Please input Type!'}]}
                                >
                                    <Select onChange={(e) => {
                                        if (types[e].includes('vendor')||types[e].includes('item')) {
                                            this.setState({
                                                singleItemInput: e
                                            })
                                        } else {
                                            this.setState({
                                                singleItemInput: 0
                                            })
                                        }
                                        if (types[e].includes('Percent')){
                                            this.setState({
                                                percent: 'ok'
                                            })
                                        } else {
                                            this.setState({
                                                percent: 'no'
                                            })
                                        }
                                    }}>
                                        <Option value={1}>Dollars off total</Option>
                                        <Option value={2}>Dollars off item</Option>
                                        <Option value={3}>Percent off total</Option>
                                        <Option value={4}>Percent off item</Option>
                                        <Option value={5}>Percent off vendor</Option>
                                        <Option value={6}>Dollars off vendor</Option>
                                    </Select>
                                </Form.Item>
                                { (this.state.singleItemInput!==0 && (types[this.state.coupon.type?this.state.coupon.type:1].includes('vendor') || types[this.state.coupon.type?this.state.coupon.type:1].includes('item') )) || !!this.state.singleItemInput ?
                                    <Form.Item
                                        label={name+" Id"}
                                        extra={"copy from the "+name+" page URL"}
                                        name={name+"Id"}
                                        rules={[{required: true, message: 'Please input Code!'}]}
                                    >
                                        <Input/>
                                    </Form.Item> : null}
                                <Form.Item
                                    label="Product Type"
                                    name="licenseType"
                                    rules={[{required: true, message: 'Please input Product Type!'}]}
                                >
                                    <Select mode={"multiple"}>
                                        <Option value={'0'}>Any</Option>
                                        <Option value={'1'}>Song</Option>
                                        <Option value={'4'}>Exclusive</Option>
                                        <Option value={'8'}>Stem</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Amount"
                                    name="amount"
                                    rules={[{required: true, message: 'Please input Amount!'}]}
                                >
                                    <InputNumber min={0} step={0.01} max={this.state.percent==='ok'||(this.state.percent!=='no'&&types[this.state.coupon.type?this.state.coupon.type:1].includes('Percent'))?100:99999}/>
                                </Form.Item>
                                <Form.Item
                                    label="Minimum"
                                    name="minimum"
                                    rules={[{required: true, message: 'Please input minimum!'}]}
                                >
                                    <InputNumber min={0} step={0.01}/>
                                </Form.Item>
                                <Form.Item
                                    label="Number of coupons"
                                    name="numberOfCoupons"
                                    rules={[{required: true, message: 'Please input number of Coupons!'}]}
                                >
                                    <InputNumber min={1} step={1} />
                                </Form.Item>
                                <Form.Item
                                    label="One Per Costumer"
                                    name="onePerCustomer"
                                    valuePropName={'checked'}
                                >
                                    <Switch/>
                                </Form.Item>
                                <Form.Item
                                    label="Disabled"
                                    name="disabled"
                                    valuePropName={'checked'}
                                >
                                    <Switch/>
                                </Form.Item>
                                <Form.Item
                                    label="Expires"
                                    name="expiry"
                                    rules={[{required: true, message: 'Please input Date!'}]}
                                >
                                    <DatePicker/>
                                </Form.Item>


                            </Form> : null}
                    </Modal>

                </Col>
            </Row>
        );
    }
}

export default connect(
    state => ({
        state
    }),
    dispatch => ({
        onFetchCoupons: (song) => {
            dispatch({
                type: "ON_FETCH_COUPONS",
                payload: song
            })
        },
        onCouponEdit: (coupon) => {
            dispatch({
                type: "ON_COUPON_UPDATE",
                payload: coupon
            })
        },
        onCouponCreate: (coupon) => {
            dispatch({
                type: "ON_COUPON_CREATE",
                payload: coupon
            })
        },
        logOut: () => {
            dispatch({
                type: "LOGOUT",
            })
        }
    })
)(Coupons);

import React, {Component} from "react";
import {
    Card,
    Col, notification,
    Row, Spin,
    Statistic,
} from "antd";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import api from "../../api";

class DashboardTab extends Component {
    constructor(props) {
        super(props);
        this.state={
            loading:true,
            stats:{},
        }
    }


    /**
     * check user has authenticated
     */
    componentDidMount() {
        fetch(api.Dashboard.url, {
            headers: {
                'cache-control':'no-cache',
                'Authorization': this.props.state.auth.accessToken
            },
            method: api.Dashboard.method,
        }).then(response =>{
            if(response.ok){
                response.json().then(response=>{
                    this.setState({stats:response,loading:false})
                })
            }else if(response.status===401){
                this.props.logOut()
                this.props.history.push('/')
            } else {
                notification.error({
                    message: 'Error',
                    description: 'Please contact administrator.'
                })
            }
        })
        if (!this.props.state || !this.props.state.auth || !this.props.state.auth.accessToken) {
            this.props.history.push("/")
        }
    }

    render() {

        return (
            this.state.loading?<div style={{padding: 150, background: '#fff', minHeight: 360, margin: "0 auto", textAlign: "center"}}>
                    <Spin size={"large"} tip={"Loading"}/>
                </div>:
            <Row className={'tab-content Dashboard'}>
                <Col span={24}>
                    <Card title={'Songs'}>
                        <Row>
                            <Col lg={6}>
                                <Statistic
                                    title="Total"
                                    value={this.state.stats.songsTotal}
                                />
                            </Col>
                            <Col lg={6}>
                                <Statistic
                                    title="Last Year"
                                    value={this.state.stats.songsLast12Month}
                                />
                            </Col>
                            <Col lg={6}>
                                <Statistic
                                    title="Last 6 months"
                                    value={this.state.stats.songsLast6Month}
                                />
                            </Col>
                            <Col lg={6}>
                                <Statistic
                                    title="Last Month"
                                    value={this.state.stats.songsLast1Month}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title={'Providers'}>
                        <Row>
                            <Col lg={6}>
                                <Statistic
                                    title="Total"
                                    value={this.state.stats.providersTotal}
                                />
                            </Col>
                            <Col lg={6}>
                                <Statistic
                                    title="Last Year"
                                    value={this.state.stats.providersLast12Month}
                                />
                            </Col>
                            <Col lg={6}>
                                <Statistic
                                    title="Last 6 months"
                                    value={this.state.stats.providersLast6Month}
                                />
                            </Col>
                            <Col lg={6}>
                                <Statistic
                                    title="Last Month"
                                    value={this.state.stats.providersLast1Month}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title={'Performers'}>
                        <Row>
                            <Col lg={6}>
                                <Statistic
                                    title="Total"
                                    value={this.state.stats.performersTotal}
                                />
                            </Col>
                            <Col lg={6}>
                                <Statistic
                                    title="Last Year"
                                    value={this.state.stats.performersLast12Month}
                                />
                            </Col>
                            <Col lg={6}>
                                <Statistic
                                    title="Last 6 months"
                                    value={this.state.stats.performersLast6Month}
                                />
                            </Col>
                            <Col lg={6}>
                                <Statistic
                                    title="Last Month"
                                    value={this.state.stats.performersLast1Month}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>

            </Row>
        );
    }
}

export default connect(
    state => ({
        state
    }),
    dispatch => ({
        logOut: () => {
            dispatch({
                type: "LOGOUT",
            })
        }
}))(withRouter(DashboardTab));

export default function users(state = {}, action){
    if(action.type === 'USERS'){
        return {
            ...action.payload,
        }
    }
    if(action.type === 'CREATE_USER'){
        return {
            ...state,
            results:[
                action.payload,
                ...state.results
            ]
        }
    }
    if(action.type === 'UPDATE_USER'){
        state.results =  state.results.filter(user=>user.userId!==action.payload.userId);
        state.results.unshift(action.payload)
        return {
            ...state,
        }
    }
    if(action.type === 'LOGOUT'){
        return {}
    }

    return state;
}
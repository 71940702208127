import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Button, Col, Modal, notification, Row, Space, Table} from "antd";
import api from "../../../../api";
import moment from "moment";
import ReactJson from "react-json-view";



class UserSubscriptions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            subscriptions:[],
            subLoading:{},
            transLoading:{},
        }
    }
    componentDidMount() {
        this.setState({
            loading:true
        })
        fetch(`${api.User.Subscriptions.url}${this.props.state.user.userId}/subscriptions`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.state.auth.accessToken
            },
            method: api.User.Subscriptions.method,
        }).then(response=>response.json()).then(subscriptions=>{
            if(subscriptions){
                console.log(subscriptions)
                this.setState({
                    subscriptions
                })
            }

        }).finally(()=>{
            this.setState({
                loading:false
            })
        })
    }
    handleShowStatus = (id)=>{
        this.setState({
            subLoading:{
                [id]:true
            }
        })
        fetch(`${api.User.SubscriptionStatus.url}${id}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.state.auth.accessToken
            },
            method: api.User.Subscriptions.method,
        }).then(response=>{
            console.log(response)
            if(response.status===200){
                return response.json()
            }
            notification.warn({
                message: 'Not Found',
            })
            return false


        }).then(data=>{
            this.setState({
                subLoading:{}
            })
            if(data){
                Modal.info({
                    title:'Subscription Status',
                    content:<div style={{overflow:'auto',maxHeight:500}}>
                        <ReactJson src={data}/>
                    </div>,
                    width:1000,
                })
            }

        })
    }
    handleShowTransactions = (id)=>{
        this.setState({
            transLoading:{
                [id]:true
            }
        })
        fetch(`${api.User.SubscriptionTransaction.url}${id}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.props.state.auth.accessToken
            },
            method: api.User.SubscriptionTransaction.method,
        }).then(response=>{
            console.log(response)
            if(response.status===200){
                return response.json()
            }
            notification.warn({
                message: 'Not Found',
            })
            return false


        }).then(data=>{
            this.setState({
                transLoading:{}
            })
            if(data){
                Modal.info({
                    title:'Subscription Transaction',
                    content:<div style={{overflow:'auto',maxHeight:500}}>
                        <ReactJson src={data}/>
                    </div>,
                    width:1000,
                })
            }

        })
    }
    render() {


        return (
            <Row>
                <Col lg={24}>
                    <Table  title={() => <h4>Subscriptions</h4>}
                          dataSource={this.state.subscriptions}
                           loading={this.state.loading}
                           rowKey={record => record.vendorSubscriptionId}
                           columns={[
                               {
                                   title: 'Vendor Subscription Id',
                                   dataIndex: 'vendorSubscriptionId',
                                   key: 'vendorSubscriptionId',
                               },
                               {
                                   title: 'Paypal Subscription Id',
                                   dataIndex: 'paypalSubscriptionId',
                                   key: 'paypalSubscriptionId',
                               },
                               {
                                   title: 'Start Date',
                                   dataIndex: 'startDate',
                                   key: 'startDate',
                                   render:i=>i?moment(i).format('MM-DD-YYYY'):null
                               },
                               {
                                   title: 'State',
                                   dataIndex: 'state',
                                   key: 'state',
                               },
                               {
                                   title: 'Subsctiption Type',
                                   dataIndex: 'subsctiptionType',
                                   key: 'subsctiptionType',
                               },
                               {
                                   title: 'Actions',
                                   dataIndex: 'paypalSubscriptionId',
                                   key: 'vendorSubscriptionId_actions',
                                   render:i=><Space>
                                       <Button loading={this.state.subLoading[i]??false} onClick={()=>this.handleShowStatus(i)}>Status</Button>
                                       <Button loading={this.state.transLoading[i]??false} onClick={()=>this.handleShowTransactions(i)}>Transactions</Button>
                                   </Space>
                               },

                           ]}
                           bordered/>

                </Col>
            </Row>

        );
    }
}

export default connect(
    state => ({
        state
    }),
    dispatch => ({
        onLogin: (token) => {
            dispatch({
                type: "LOGIN",
                payload: token
            })
        },
        logOut: () => {
            dispatch({
                type: "LOGOUT",
            })
        }
    }))(withRouter(UserSubscriptions));

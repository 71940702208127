export default function songs(state = {}, action){
    if(action.type === 'SONGS'){
        return {
            ...action.payload,
        }
    }
    if(action.type === 'REMOVE_SONGS'){
        return {
            ...state,
            results:state.results.map(i=>{
                if(action.payload.productId===i.songId){
                    if(action.payload.deleteType==='Hard'){
                        if(action.payload.itemsDeleted.includes("WAVInstrumentals")){
                            delete i.wavInstrumental;
                        }
                        if(action.payload.itemsDeleted.includes("STEMS")){
                            delete i.masterTrack;
                        }
                        if(action.payload.itemsDeleted.includes("AllInstrumentals")){
                            delete i.mp3Instrumental;
                            delete i.mp3InstrumentalClip;
                            delete i.wavInstrumental;
                        }
                        if(action.payload.itemsDeleted.includes("All")){
                            return false
                        }
                    }else{
                        if(action.payload.itemsDeleted.includes("WAVInstrumentals")){
                            i.isWAVDeleted = true

                        }
                        if(action.payload.itemsDeleted.includes("STEMS")){
                            i.isSTEMSDeleted = true
                        }
                        if(action.payload.itemsDeleted.includes("AllInstrumentals")){
                            i.isAllInstrumentalsDeleted = true
                        }
                        if(action.payload.itemsDeleted.includes("All")){
                            i.isDeleted = true;
                        }
                    }
                }
                 return i
            }).filter(i=>i)
        }
    }
    if(action.type === 'ON_ADD_NOTE'){
      state.results= state.results.map(song=>{
           if(song.songId===action.payload.songId){
               return action.payload
           }else{
               return song
           }
       })
        return {
            ...state,
        }
    }
    if(action.type === 'LOGOUT'){
        return {}
    }

    return state;
}

import React, {Component} from "react";
import {
    Button,
    Col,
    Form,
    Input,
    Row,
    Table,
    notification,
    Modal, Typography,
} from "antd";
import api from "../../api";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {PlusCircleOutlined} from "@ant-design/icons";
const {Title} = Typography;
class SoundsLikeTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addSoundsLike:false,
            soundsLikes:{},
            loading:false,

        }
        this.soundsForm=React.createRef();
    }


    componentDidMount() {
        this.setState({
            loading:true
        })
        fetch(api.Song.Categories.url, {
            headers: {
                'cache-control':'no-cache',
                'Authorization': this.props.state.auth.accessToken
            },
            method:api.Song.Categories.method,
        })
            .then(response => response.json()).then(response=>{
                this.setState({
                    soundsLikes:response.soundsLikes,
                    loading:false,
                    addSoundsLike:false,
                })

        })
    }

     handleAddSoundsLike (values){
        fetch(api.Categories.Sounds.url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'cache-control':'no-cache',
                'Authorization': this.props.state.auth.accessToken
            },
            method:api.Categories.Sounds.method,
            body:JSON.stringify(values)
        })
            .then(response => {
                if (response.ok){
                    notification.info({
                        message: 'Done',
                        description: 'Sounds Like is added'
                    })
                    this.componentDidMount();
                }else{
                    notification.error({
                        message: 'Error',
                        description: 'Somethings wrong'
                    })
                }
            })

    }
    render() {
        const columns = [
            {
                title: 'Sounds Like',
                dataIndex: 'name',
                key: 'soundslike',
            },
        ];
        return (
            <Row className={'tab-content'}>
                <Col lg={24}>
                    <Title level={4}>Sounds Like</Title>
                    <Button onClick={()=>this.setState({
                        addSoundsLike:true
                    })} style={{marginBottom: '10px'}}><PlusCircleOutlined />Create New Sounds Like</Button>
                    <Table dataSource={this.state.soundsLikes[0]?this.state.soundsLikes:[]} rowKey={record => record.email} columns={columns} loading={this.state.loading} size={"small"}
                    />

                </Col>
                <Modal
                    width={400}
                    title={"Add Sounds Like"}
                    visible={this.state.addSoundsLike}
                    onOk={() => this.soundsForm.current.submit()}
                    onCancel={() =>{this.setState({addSoundsLike:false}); this.soundsForm.current.resetFields()}}
                    confirmLoading={this.state.loading}

                >
                        <Form
                            onFinish={this.handleAddSoundsLike.bind(this)}
                            ref={this.soundsForm}
                        >
                            <Form.Item
                                label="Sounds Like"
                                name="Name"
                                rules={[{required: true, message: 'Please input Name!'}]}
                            >
                                <Input/>
                            </Form.Item>
                        </Form>
                </Modal>
            </Row>
        );
    }
}
export default connect(
    state => ({
        state
    }), dispatch => ({
    logOut: () => {
        dispatch({
            type: "LOGOUT",
        })
    }
}))(withRouter(SoundsLikeTab));

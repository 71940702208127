import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import SalesTab from "../../SalesTab";



class UserOrders extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            orders:[],
            subLoading:{},
            transLoading:{},
        }
    }

    render() {


        return (
           <SalesTab userId={this.props.state.user.userId}/>
        );
    }
}

export default connect(
    state => ({
        state
    }),
    dispatch => ({

    }))(withRouter(UserOrders));

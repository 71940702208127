export default function auth(state = {}, action){
    if(action.type === 'LOGIN'){
        return{
            ...state,
            accessToken: 'Bearer ' + action.payload.accessToken,
            user: action.payload,
        }
    }
    if(action.type === 'LOGOUT'){
        return {}
    }

    return state;
}
import React, {Component} from "react";
import {Button, Col, Form, Input, Row, Table, Typography, notification, DatePicker} from "antd";
import api from "../../api";
import {connect} from "react-redux";
import {SearchOutlined} from "@ant-design/icons";
import {withRouter} from "react-router-dom";
import moment from "moment";
const {Title} = Typography;
const {RangePicker} = DatePicker;


class CancelledApplicants extends Component {

    constructor(props) {
        super(props);
        this.state = {
            createFormVisibility: false,
            updateFormVisibility: false,
            passModal:false,
            loading: false,
            filters:{},
            userDat:{},
            applicants:{},
        }
        this.form=React.createRef();
    }


    componentDidMount() {
        this.searchApplicants(new URLSearchParams(this.props.history.location.search))
    }
    searchApplicants = (params) => {
        if(params['date']){
            params['createdFrom'] =moment.utc(params.date[0]).format()
            params['createdTo'] =moment.utc(params.date[1]).format()
            delete params['date'];
        }
        if(params['submited']){
            params['submitedFrom'] =moment.utc(params.submited[0]).format()
            params['SubmitedTo'] =moment.utc(params.submited[1]).format()
            delete params['submited'];
        }
        let search;
        if(params){
            Object.keys(params).forEach(key => params[key] === undefined && delete params[key])
            search= new URLSearchParams(params).toString();
        }
        this.setState({
            loading: true,
        });
        console.log(params)
        this.props.history.push({
            search:params?`?${search}`:'',
            path:'/dashboard/cancelled-applicants'
        })
        fetch(api.CanceledApplicants.Get.url+(params?`?${search}`:''), {
            headers: {
                'cache-control':'no-cache',
                'Authorization': this.props.state.auth.accessToken
            },
            method: api.CanceledApplicants.Get.method,
        })
            .then(response =>{
                if(response.ok){
                    response.json().then((response) => {
                        this.setState({
                            loading: false,
                            filters:params,
                            applicants:response
                        });
                    })
                }else if(response.status===401){
                    this.props.logOut()
                    this.props.history.push('/')
                } else {
                    notification.error({
                        message: 'Error',
                        description: 'Please contact administrator.'
                    })
                }
            })
    }

    render() {
        const columns = [
            {
                title: 'Registration Date',
                dataIndex: 'createdDate',
                sorter: (a, b) => moment(a.createdDate).unix() - moment(b.createdDate).unix(),
                key: 'createdDate',
                fixed: 'left',
                render:item=>moment(item).format('YYYY-MM-DD')
            },
            {
                title: 'Submission Date',
                dataIndex: 'submitionDate',
                sorter: (a, b) => moment(a.submitionDate).unix() - moment(b.submitionDate).unix(),
                key: 'submitionDate',
                fixed: 'left',
                render:item=>item?moment(item).format('YYYY-MM-DD'):'Not submitted'
            },
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: (a, b) => a.name.localeCompare(b.name),
                key: 'name',
            },
            {
                title: 'Email',
                dataIndex: 'email',
                sorter: (a, b) => a.email.localeCompare(b.email),
                key: 'email',
                columnWidth: 10
            },
            {
                title: 'Decline Date',
                dataIndex: 'declineDate',
                sorter: (a, b) => moment(a.declineDate).unix() - moment(b.declineDate).unix(),
                key: 'declineDate',
                render:item=>moment(item).format('YYYY-MM-DD')
            },
        ];
        return (
            <Row className={'tab-content'}>
                <Col lg={24}>
                    <Form  layout={'horizontal'} onFinish={this.searchApplicants}>
                        <Row className={'filters-row'}>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Title level={4}>Basic filters</Title>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={24} xs={24}>
                                <Form.Item name={'name'}>
                                    <Input placeholder="Name"/>
                                </Form.Item>
                                <Form.Item name={'email'}>
                                    <Input placeholder="email"/>
                                </Form.Item>
                            </Col>
                            <Col xl={6} lg={8} md={8} sm={24} xs={24}>
                                <Form.Item name="date"  rules={[ {
                                    type: 'array',
                                    message: 'Please select time!',
                                },]}>
                                    <RangePicker placeholder={['Registered From','Registered To']} format="YYYY-MM-DD" />
                                </Form.Item>
                                <Form.Item name="submited"  rules={[ {
                                    type: 'array',
                                    message: 'Please select time!',
                                },]}>
                                    <RangePicker placeholder={['Submitted From','Submitted To']} format="YYYY-MM-DD" />
                                </Form.Item>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} xs={24}>
                                <Button htmlType={'submit'} type="primary"><SearchOutlined />Search</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col  lg={24}>
                    <Table dataSource={this.state.applicants?.results??[]} rowKey={record => record.email} columns={columns} loading={this.state.loading} size={"small"}
                           pagination={{
                               defaultPageSize: 20,
                               showSizeChanger:false,
                               total:this.state.applicants?.pagination?.totalCount??0,
                               onChange:(e)=>{
                                   this.searchApplicants({...this.state.filters,start:(e*20)-20})
                               },
                               current: this.state.applicants.pagination&&this.state.applicants.pagination.nextStart?Math.ceil(this.state.applicants.pagination.nextStart / 20):this.state.applicants.pagination?.totalCount?Math.ceil(this.state.applicants.pagination.totalCount/20):1
                           }}
                    />

                </Col>
            </Row>
        );
    }
}
export default connect(
    state => ({
        state
    }),
    dispatch => ({
        logOut: () => {
            dispatch({
                type: "LOGOUT",
            })
        }
    })
)(withRouter(CancelledApplicants));

export default function coupons(state = {}, action) {
    if (action.type === 'ON_FETCH_COUPONS') {
        return {...action.payload}
    }
    if (action.type === 'ON_COUPON_UPDATE') {
        state.results = state.results.map(coupon => {
            if (coupon.couponId === action.payload.couponId) {
                return action.payload
            } else {
                return coupon
            }
        })
        return {
            ...state
        }
    }
    if (action.type === 'ON_COUPON_CREATE') {

        return {
            ...state,
            results: [
                action.payload,
                ...state.results,
            ]
        }
    }

    if (action.type === 'LOGOUT') {
        return {}
    }

    return state;
}